export const getPaymentMethodString = (disbursement) => {
  const bankOrWalletName = disbursement?.payment.code;
  const paymentMethod = disbursement?.payment?.method;

  switch (paymentMethod) {
    case 'Virtual Account':
      return bankOrWalletName + ' ' + paymentMethod;
    case 'E-money':
      return bankOrWalletName;
    case 'Manual Transfer':
      return 'Manual Transfer' + ' ' + bankOrWalletName;
    case 'Credit Card':
      return 'Credit Card / Debit Card';
    case 'QRIS':
      return 'QRIS';
    default:
      return 'Belum Memilih Metode Pembayaran';
  }
};
