import React from 'react';
import { InputField } from 'components/Global';
import { indonesianDateFormat } from 'utils/helpers';
import { ReactComponent as CarChair } from '../../../../../icons/car-chair-icon.svg';

const DetailSewa = ({ data, vehicleById }) => {
  return (
    <div className="detail-order-with-driver__rent-detail">
      <div className="detail-order-with-driver__rent-detail-title">
        <h1>Detail Sewa</h1>
      </div>
      <div className="detail-order-with-driver__rent-detail-body">
        <InputField label="Tipe Sewa" disabled value={data?.order_type_id === 1 ? 'Harian' : '-'} />
        <InputField label="Jenis Mobil" disabled value={vehicleById.name ?? '-'} />

        <div className="detail-order-with-driver__rent-detail-body__date-time">
          <InputField
            label="Tanggal Mulai"
            disabled
            value={
              data?.order_detail?.start_booking_date
                ? indonesianDateFormat(data?.order_detail?.start_booking_date)
                : '-'
            }
          />
          <InputField
            label="Tanggal Selesai"
            disabled
            value={
              data?.order_detail?.end_booking_date ? indonesianDateFormat(data?.order_detail?.end_booking_date) : '-'
            }
          />
        </div>

        <InputField label="Durasi" disabled value={`${data?.order_detail?.booking_zones?.length} Hari` || '-'} />

        <div className="detail-order-with-driver__rent-detail-body__payment-passenger">
          <InputField
            label="Tipe Pembayaran"
            disabled
            value={
              data?.type === 'FULL'
                ? 'Pembayaran Full'
                : data?.type === 'HALF'
                ? `Pembayaran DP ${
                    data?.order_detail?.dp_rule?.formula_percentage?.value
                      ? `${data.order_detail.dp_rule.formula_percentage.value}%`
                      : ''
                  }`
                : '-'
            }
          />
          <InputField
            label="Jumlah Penumpang"
            icon={<CarChair />}
            iconPosition="start"
            disabled
            value={data?.order_detail?.passenger_number !== 0 ? `${data?.order_detail?.passenger_number} Orang` : 0}
          />
        </div>

        <div className="with-driver-form__form__baggage-passenger">
          <InputField label="Kapasitas Koper" value={vehicleById?.max_suitcase || 0} disable />
          <InputField label="Koper yang dibawa" disabled value={data?.order_detail?.baggage ?? 0} />
        </div>
      </div>
    </div>
  );
};

export default DetailSewa;
