import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as CarChair } from 'icons/car-chair-icon.svg';
import { ReactComponent as CarIcon } from 'icons/car-filled-logo.svg';
import { ReactComponent as PointsIcon } from 'icons/points-icon.svg';
import { Button, InputField, TableWrapper } from 'components/Global';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { checkEmptyObject, checkPermission, setCurrency } from 'utils/functionality';
import countryCodes from 'utils/country-codes.json';
import { getCustomerData } from 'features/user/slice';
import { fetchOrderById, fetchSummaryOrder } from 'features/orders/actions';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import SelectFieldCar from 'components/Global/SelectFieldCar';
import InputAdditionals from 'components/Global/InputAdditionals';
import { getVehicleDetailByFilter, getVehiclesByFilter } from 'features/vehicle/action';
import SelectFieldShuttle from 'components/Global/SelectFieldShuttle';
import { getAllDeliveryLocations } from 'features/delivery-location/actions';
import { getServices } from 'features/services/actions';
import SelectFieldDate from 'components/Global/SelectFieldDate';
import { addDays, format, parseISO, subHours } from 'date-fns';
import SelectFieldTime from 'components/Global/SelectFieldTime';
import { rentalLocation } from 'features/rental-location/slice';
import { getApprovalSettings, updateApprovalOrder } from 'features/approvals/actions';
import { getRentalLocationById } from 'features/rental-location/actions';
import InputViolation from 'components/Global/InputViolation';
import moment from 'moment-timezone';

const INITIAL_STATE = {
  booking_price: 0,
  order_detail: {
    passenger_number: '',
    baggage: '',
    end_booking_date: '',
    end_booking_time: '',
    rental_delivery_location: '',
    rental_delivery_location_detail: '',
    rental_return_location: '',
    rental_return_location_detail: '',
    start_booking_date: '',
    start_booking_time: '',
    vehicle_id: 0,
    location_id: 0,
    loc_time_id: '',
    landing_time: '',
    flight_number: '',
  },
  order_type_id: 0,
  deposit: 0,
  over_time_price: 0,
  over_time: 0,
};

const overtime = [
  {
    id: 'ot0',
    name: 'Tidak Ada Overtime',
    value: 0,
  },
  {
    id: 'ot1',
    name: '1 Jam',
    value: 1,
  },
  {
    id: 'ot2',
    name: '2 Jam',
    value: 2,
  },
  {
    id: 'ot3',
    name: '3 Jam',
    value: 3,
  },
  {
    id: 'ot4',
    name: '4 Jam',
    value: 4,
  },
  {
    id: 'ot5',
    name: '5 Jam',
    value: 5,
  },
  {
    id: 'ot6',
    name: '6 Jam',
    value: 6,
  },
];

const EditOrder = ({ transactionKey }) => {
  const { setShowConfirmation, showToast, setShowSpinner } = useAppContext();

  const [selectedCountry, setSelectedCountry] = useState({
    name: '',
    code: '',
  });
  const [orderData, setOrderData] = useState(INITIAL_STATE);
  const [selectedOvertime, setSelectedOvertime] = useState('');
  const [selectedCar, setSelectedCar] = useState(undefined);
  const [vehicleInput, setVehicleInput] = useState('');
  // ADDITIONAL STATE
  const [additionalList, setAdditionalList] = useState([]);
  const [additionalPrice, setAdditionalPrice] = useState(0);
  // VIOLIATION STATE
  const [violationList, setViolationList] = useState([]);
  const [violationPrice, setViolationPrice] = useState(0);
  const [deliveryLocation, setDeliveryLocation] = useState({
    id: 0,
    name: '',
  });
  const [returnLocation, setReturnLocation] = useState({
    id: 0,
    name: '',
  });
  const [deliveryLocationDetail, setDeliveryLocationDetail] = useState('');
  const [returnLocationDetail, setReturnLocationDetail] = useState('');
  const [flightNumber, setFlightNumber] = useState('');
  const [startRentDate, setStartRentDate] = useState('');
  const [endRentDate, setEndRentDate] = useState('');
  const [baggage, setBaggage] = useState(0);
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customer = useSelector(getCustomerData);
  const { selected: vehicleById, data: vehicleList } = useSelector((state) => state.vehicle);
  const { data: rentalServicesData } = useSelector((state) => state.services);
  const { data: dataDeliveryLocation } = useSelector((state) => state.deliveryLocation);
  const { data: summaryData } = useSelector((state) => state.summaryOrder);
  const approvalData = useSelector((state) => state.approvals.approvalSetting);
  const { data, status } = useSelector((state) => state.detailOrder);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);

  const [searchParams] = useSearchParams();

  const findCountryByCode = (code) => countryCodes.find((item) => item.dial_code == code);

  const isUpdate = searchParams.get('update');

  const getTotalPrice = (total, point, voucher) => {
    let finalPrice = total - point;
    const voucherValue = voucher?.length > 0 ? voucher[0].value : undefined;
    if (voucherValue) {
      finalPrice -= voucherValue;
    }
    return isNaN(finalPrice) ? 0 : finalPrice;
  };

  useEffect(() => {
    if (selectedCar) {
      setIsPageLoaded(true);
    }
  }, [selectedCar]);

  useEffect(() => {
    if (!isUpdate) return;
    if (checkEmptyObject(data)) return;
    const approvalKey = data.is_admin_creation ? 'update-order-to-higher-level' : 'update-order-to-customer';
    dispatch(getApprovalSettings({ limit: 10, key: approvalKey }));
    dispatch(getAllDeliveryLocations({ currentPage: 1, limit: 30, id: data.order_detail.location_id }));
    dispatch(getRentalLocationById(data.order_detail.location_id));
    dispatch(getServices());
  }, [isUpdate, data]);

  useEffect(() => {
    dispatch(fetchOrderById(transactionKey));
  }, [transactionKey]);

  useEffect(() => {
    if (customer) {
      setSelectedCountry(findCountryByCode(data?.is_admin_creation ? data?.phone_country_code : customer?.phone_code));
    }
  }, [customer, data]);

  useEffect(() => {
    if (status === 'loading') {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [status]);

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(data, 'order_additional_fees')) {
      if (data.order_additional_fees !== null) {
        setAdditionalList(data.order_additional_fees);
        setAdditionalPrice(0);
      }
    }
  }, [data]);

  useEffect(() => {
    if (additionalList.length > 0) {
      setAdditionalPrice(() => additionalList.map((item) => item.value).reduce((acc, cVal) => acc + cVal, 0));
      return;
    }
    setAdditionalPrice(0);
  }, [additionalList]);

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(data, 'order_violations')) {
      if (data.order_violations !== null) {
        setViolationList(data.order_violations);
        setViolationPrice(0);
      }
    }
  }, [data]);

  useEffect(() => {
    if (violationList.length > 0) {
      setViolationPrice(() => violationList?.map((item) => item.cost).reduce((acc, cVal) => acc + cVal, 0));
      return;
    }
    setViolationPrice(0);
  }, [violationList]);

  useEffect(() => {
    if (checkEmptyObject(vehicleById) || isPageLoaded) return;
    setSelectedCar(vehicleById);
  }, [vehicleById, isPageLoaded]);

  const backHandler = () => {
    navigate(-1);
  };

  const updateHandler = () => {
    if (
      orderData.order_detail.end_booking_date === '' ||
      orderData.order_detail.end_booking_time === '' ||
      orderData.order_detail.start_booking_date === '' ||
      orderData.order_detail.start_booking_time === '' ||
      isNaN(baggage) ||
      baggage === '' ||
      isNaN(orderData.order_detail.passenger_number) ||
      orderData.order_detail.passenger_number === '' ||
      deliveryLocation.name === '' ||
      returnLocation.name === '' ||
      deliveryLocationDetail === '' ||
      returnLocationDetail === ''
    ) {
      return showToast({ type: 'warning', message: 'Harap Mengisi Semua Field!' });
    }

    if (!selectedCar.id) {
      return showToast({ type: 'warning', message: 'Harap Memilih Mobil!' });
    }

    delete orderData.order_detail.identity;
    delete orderData.order_detail.booking_zones;

    const utcDifference = moment
      .tz(`${orderData.order_detail.start_booking_date}`, orderData.order_detail.loc_time_id)
      .format('Z')
      .split(':')[0];

    const convertStartBookingTimeUTC = subHours(
      parseISO(`${orderData.order_detail.start_booking_date} ${orderData.order_detail.start_booking_time}`),
      parseInt(utcDifference),
    );

    const convertEndBookingTimeUTC = subHours(
      parseISO(`${orderData.order_detail.end_booking_date} ${orderData.order_detail.end_booking_time}`),
      parseInt(utcDifference),
    );

    const priceDifference = orderData.total_amount_order - summaryData.total_payment;

    const payload = {
      reference_id: orderData.id,
      reference_type: 'order',
      approval_setting_id: approvalData.data[0].id,
      data: {
        ...orderData,
        disbursement: {
          ...orderData.disbursement,
          payment_method_id: 0,
          ppn_internal_percentage: 0,
          disbursement_confirmation_image: '',
          payment_platform_fee_type_fixed: 0,
          total_amount_customer_service_fee:
            orderData.total_amount_order === summaryData.total_payment
              ? orderData.disbursement.total_amount_customer_service_fee
              : 0,
          total_amount_external_payment_fee:
            orderData.total_amount_order === summaryData.total_payment
              ? orderData.disbursement.total_amount_external_payment_fee
              : 0,
          payment_platform_fee_type_percentage: 0,
          total_amount_with_customer_service_fee:
            orderData.total_amount_order === summaryData.total_payment || priceDifference > 0
              ? orderData.disbursement.total_amount_with_customer_service_fee
              : 0,
          total_net_amount:
            orderData.total_amount_order === summaryData.total_payment ? orderData.disbursement.total_net_amount : 0,
        },
        booking_price: summaryData.booking_price,
        deposit: summaryData.deposit,
        price_per_day: summaryData.price_per_day,
        total_payment:
          orderData.total_amount_order === summaryData.total_payment
            ? getTotalPrice(orderData?.total_payment, orderData?.point, orderData?.vouchers)
            : getTotalPrice(summaryData?.total_payment, orderData?.point, orderData?.vouchers),
        deposit_e_toll: summaryData.deposit_e_toll,
        outside_operational_charge: summaryData.outside_operational_charge,
        one_day_order_charge: summaryData.one_day_order_charge,
        exceed_passenger_price: summaryData.exced_max_passenger_charge,
        over_time_price: summaryData.over_time_price,
        over_time: summaryData.over_time_hour,
        rental_delivery_fee: summaryData.rental_delivery_fee,
        rental_return_fee: summaryData.rental_return_fee,
        remainder: orderData.type === 'FULL' ? 0 : summaryData.remainder,
        down_payment: orderData.type === 'FULL' ? 0 : summaryData.total_dp,
        total_amount_order:
          orderData.total_amount_order === summaryData.total_payment
            ? orderData.total_amount_order
            : summaryData.total_payment,
        total_diff_price_with_approval: 0,
        order_detail: {
          ...orderData.order_detail,
          baggage: baggage,
          start_booking_date: format(convertStartBookingTimeUTC, 'yyyy-MM-dd'),
          start_booking_time: format(convertStartBookingTimeUTC, 'HH:mm'),
          end_booking_date: format(convertEndBookingTimeUTC, 'yyyy-MM-dd'),
          end_booking_time: format(convertEndBookingTimeUTC, 'HH:mm'),
          vehicle_id: selectedCar.id,
          rental_return_location_detail: returnLocationDetail,
          rental_delivery_location_detail: deliveryLocationDetail,
          rental_delivery_location: deliveryLocation.name,
          rental_return_location: returnLocation.name,
          flight_number: flightNumber,
          landing_time: orderData.order_detail.landing_time,
        },
        order_additional_fees: additionalList,
        order_violations: violationList,
      },
    };
    setShowConfirmation({
      message: 'Apakah anda yakin ingin melanjutkan proses recalculate?',
      show: true,
      onClick: async () => {
        try {
          await dispatch(updateApprovalOrder(payload)).unwrap();
          showToast({ type: 'success', message: 'Approval recalculate berhasil dilakukan' });
          navigate(-1);
        } catch (err) {
          showToast({ type: 'error', message: 'Approval recalculate gagal dilakukan' });
        }
      },
    });
  };

  useEffect(() => {
    if (!checkEmptyObject(data)) {
      const getDeliveryLocation = dataDeliveryLocation?.shuttle?.find(
        (item) => item.name === data.order_detail.rental_delivery_location,
      );
      const getReturnLocation = dataDeliveryLocation?.shuttle?.find(
        (item) => item.name === data.order_detail.rental_return_location,
      );
      setDeliveryLocation(getDeliveryLocation ? getDeliveryLocation : { id: 0, name: '' });
      setReturnLocation(getReturnLocation ? getReturnLocation : { id: 0, name: '' });
      setDeliveryLocationDetail(data.order_detail?.rental_delivery_location_detail || '');
      setReturnLocationDetail(data.order_detail?.rental_return_location_detail || '');
      setFlightNumber(data.order_detail?.flight_number || '');
      setStartRentDate(parseISO(data.order_detail.start_booking_date));
      setEndRentDate(parseISO(data.order_detail.end_booking_date));
      setSelectedOvertime(overtime.find((item) => item.value === data.over_time)?.id);
      setBaggage(data.order_detail?.baggage);
      let bookingEndTimeWithoutOvertime = '';
      if (data.over_time > 0) {
        const subtractOvertime = subHours(
          parseISO(
            `${data.order_detail.end_booking_date.slice(0, 5)} ${data.order_detail.end_booking_time.slice(0, 5)}`,
          ),
          data.over_time,
        );
        const hour = format(subtractOvertime, 'HH:mm');
        bookingEndTimeWithoutOvertime = hour;
      }
      setOrderData({
        ...data,
        order_detail: {
          ...data.order_detail,
          start_booking_time: data.order_detail.start_booking_time.slice(0, 5),
          end_booking_time:
            data.over_time > 0 ? bookingEndTimeWithoutOvertime : data.order_detail.end_booking_time.slice(0, 5),
        },
      });
    }
  }, [data, dataDeliveryLocation]);

  useEffect(() => {
    if (!rentalServicesData.length) return;
    if (checkEmptyObject(data) || !isUpdate) return;
    const subServiceWithoutDriver = rentalServicesData
      ?.find((item) => item.name === 'Sewa Mobil')
      ?.sub_services?.find((item) => item.name === 'Daily')
      ?.facilities?.find((item) => item.name === 'Without Driver');

    dispatch(
      getVehiclesByFilter({
        subServiceId: subServiceWithoutDriver?.id,
        order_id: orderData?.transaction_key,
        limit: 0,
        supportDriver: false,
        customOrder: true,
        startTrip: `${format(
          parseISO(
            orderData?.order_detail?.start_booking_date
              ? orderData.order_detail.start_booking_date
              : data.order_detail.start_booking_date,
          ),
          'yyyy-MM-dd',
        )} ${
          orderData?.order_detail?.start_booking_time
            ? orderData.order_detail.start_booking_time
            : data.order_detail.start_booking_time.slice(0, 5)
        }`,
        endTrip: `${format(
          parseISO(
            orderData?.order_detail?.end_booking_date
              ? orderData.order_detail.end_booking_date
              : data.order_detail.end_booking_date,
          ),
          'yyyy-MM-dd',
        )} ${
          orderData?.order_detail?.end_booking_time
            ? orderData.order_detail.end_booking_time
            : data.order_detail.end_booking_time.slice(0, 5)
        }`,
        locationRental: orderData?.order_detail?.location_id
          ? orderData.order_detail.location_id
          : data.order_detail.location_id,
      }),
    );
    dispatch(
      getVehicleDetailByFilter({
        id: orderData?.order_detail?.vehicle_id ? orderData.order_detail.vehicle_id : data.order_detail.vehicle_id,
        supportDriver: false,
        startTrip: `${format(
          parseISO(
            orderData?.order_detail?.start_booking_date
              ? orderData.order_detail.start_booking_date
              : data.order_detail.start_booking_date,
          ),
          'yyyy-MM-dd',
        )} ${
          orderData?.order_detail?.start_booking_time
            ? orderData.order_detail.start_booking_time
            : data.order_detail.start_booking_time.slice(0, 5)
        }`,
        endTrip: `${format(
          parseISO(
            orderData?.order_detail?.end_booking_date
              ? orderData.order_detail.end_booking_date
              : data.order_detail.end_booking_date,
          ),
          'yyyy-MM-dd',
        )} ${
          orderData?.order_detail?.end_booking_time
            ? orderData.order_detail.end_booking_time
            : data.order_detail.end_booking_time.slice(0, 5)
        }`,
      }),
    );
  }, [data, isUpdate, orderData, rentalServicesData]);

  const intersectionAction = () => {
    if (!rentalServicesData.length) return;
    if (orderData.order_detail.vehicle_id !== '') return;

    const subServiceWithoutDriver = rentalServicesData
      ?.find((item) => item.name === 'Sewa Mobil')
      ?.sub_services?.find((item) => item.name === 'Daily')
      ?.facilities?.find((item) => item.name === 'Without Driver');

    const payload = {
      startTrip: `${orderData.order_detail.start_booking_date} ${orderData.order_detail.start_booking_time}`,
      endTrip: `${orderData.order_detail.end_booking_date} ${orderData.order_detail.end_booking_time}`,
      page: 0,
      locationRental: orderData.order_detail.location_id,
      supportDriver: false,
      customOrder: true,
      order_id: orderData.transaction_key,
      limit: 0,
      subServiceId: subServiceWithoutDriver?.id,
    };

    // if last page then just return nothing
    if (vehicleList.pagination.page >= vehicleList.pagination.last_page) return;

    // if not last page fetch
    dispatch(getVehiclesByFilter(payload));
  };

  useEffect(() => {
    if (!isPageLoaded) return;
    if (
      orderData.order_detail.end_booking_date === '' ||
      orderData.order_detail.end_booking_time === '' ||
      orderData.order_detail.start_booking_date === '' ||
      orderData.order_detail.start_booking_time === '' ||
      !rentalServicesData.length ||
      !selectedCar?.id
    )
      return;
    const subServiceWithoutDriver = rentalServicesData
      ?.find((item) => item.name === 'Sewa Mobil')
      ?.sub_services?.find((item) => item.name === 'Daily')
      ?.facilities?.find((item) => item.name === 'Without Driver');

    dispatch(
      fetchSummaryOrder({
        end_booking_date: orderData?.order_detail.end_booking_date,
        end_booking_time: orderData?.order_detail.end_booking_time,
        start_booking_date: orderData?.order_detail.start_booking_date,
        start_booking_time: orderData?.order_detail.start_booking_time,
        deposit: orderData?.deposit,
        deposit_e_toll: orderData?.deposit_e_toll,
        order_type_id: 1,
        vehicle_id: selectedCar?.id,
        without_driver: 1,
        rental_return_id: returnLocation.id,
        rental_delivery_id: deliveryLocation.id,
        promotion_id: selectedCar?.promo?.promo_id,
        pasengger_number: orderData?.order_detail.passenger_number,
        vehicle_category_id: selectedCar.category?.id,
        location_id: orderData?.order_detail.location_id,
        point: orderData?.point,
        sub_services_id: subServiceWithoutDriver?.id,
        overtime: overtime.find((item) => item.id === selectedOvertime)?.value,
        order_additional_fees: additionalList,
        violations: violationPrice,
        order_id: orderData.id,
      }),
    );
  }, [
    orderData,
    selectedCar,
    rentalServicesData,
    deliveryLocation,
    rentalLocation,
    selectedOvertime,
    additionalList,
    violationPrice,
    isPageLoaded,
  ]);

  return (
    <div className="detail-order">
      <div className="detail-order__back-btn" onClick={backHandler}>
        <LeftArrow />
        <p>Kembali</p>
      </div>
      <TableWrapper icon={<CarIcon fill="#009EF7" width="25px" height="25px" />} title="Lepas Kunci">
        <div className="detail-order__renter-detail">
          <div className="detail-order__renter-detail-title">
            <h1>Detail Penyewa</h1>
          </div>
          <div className="detail-order__renter-detail-body">
            <InputField label="Nama Lengkap" disabled value={data?.user_name || '-'} />
            <InputField label="Email" disabled value={data?.email || '-'} />

            <div className="detail-order__renter-detail-body__phone-number">
              <InputField
                label="No Handphone"
                disabled
                value={data?.is_admin_creation ? data?.phone_country_code : customer?.phone_code || '-'}
                icon={
                  <img
                    src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                    width={26}
                    height={16}
                    alt={selectedCountry?.name + ' nation flag'}
                  />
                }
              />
              <InputField value={data?.phone_number || '-'} disabled />
            </div>

            <InputField label="Negara" disabled value={selectedCountry?.name || '-'} />

            <div className="detail-order__renter-detail-body__phone-number">
              <div className="detail-order__renter-detail-body__phone-number-wrapper">
                <InputField
                  label="Whatsapp"
                  disabled
                  value={data?.is_admin_creation ? data?.phone_country_code : customer?.phone_code || '-'}
                  icon={
                    <img
                      src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                      width={26}
                      height={16}
                      alt={selectedCountry?.name + ' nation flag'}
                    />
                  }
                />
                <div className="detail-order__renter-detail-body__image-wrapper"></div>
              </div>
              <InputField disabled value={data?.wa_number || '-'} />
            </div>
          </div>
        </div>

        <div className="detail-order__rent-detail">
          <div className="detail-order__rent-detail-title">
            <h1>Detail Sewa</h1>
          </div>
          <div className="detail-order__rent-detail-body">
            <SelectFieldShuttle
              label="Lokasi Pengantaran"
              htmlFor="lokasi-pengantaran"
              value={deliveryLocation.id}
              data={dataDeliveryLocation.shuttle}
              onChange={(item) => {
                setDeliveryLocation(item);
                setOrderData({
                  ...orderData,
                  rental_delivery_fee: item.fee,
                  order_detail: {
                    ...orderData.order_detail,
                    rental_delivery_location: item.name,
                    location_id: item.location_id,
                  },
                });
              }}
              placeholder="Masukan Lokasi Pengantaran"
            />
            <InputField
              label="Detail Lokasi Pengantaran"
              value={deliveryLocationDetail}
              onChange={(e) => setDeliveryLocationDetail(e.target.value)}
            />

            <SelectFieldShuttle
              label="Lokasi Pengembalian"
              htmlFor="lokasi-pengembalian"
              value={returnLocation.id}
              data={dataDeliveryLocation.shuttle}
              onChange={(item) => {
                setReturnLocation(item);
                setOrderData({
                  ...orderData,
                  rental_return_fee: item.fee,
                  order_detail: {
                    ...orderData.order_detail,
                    rental_return_location: item.name,
                    location_id: item.location_id,
                  },
                });
              }}
              placeholder="Masukan Lokasi Pengembalian"
            />
            <InputField
              label="Detail Lokasi Pengambilan"
              value={returnLocationDetail}
              onChange={(e) => setReturnLocationDetail(e.target.value)}
            />

            {orderData?.order_detail?.flight_number || deliveryLocation?.airport || returnLocation?.airport ? (
              <InputField
                label="Nomor Penerbangan"
                value={flightNumber}
                onChange={(e) => setFlightNumber(e.target.value)}
              />
            ) : null}
            {orderData?.order_detail?.landing_time || deliveryLocation?.airport || returnLocation?.airport ? (
              <SelectFieldTime
                label="Jam Landing"
                htmlFor="jam-landing"
                placeholder="00:00"
                value={orderData.order_detail.landing_time}
                onChange={(hour, minute) => {
                  setOrderData({
                    ...orderData,
                    order_detail: { ...orderData.order_detail, landing_time: `${hour}:${minute ? minute : '00'}` },
                  });
                }}
              />
            ) : null}

            <InputField label="Tipe Sewa" disabled value="Harian" />
            <SelectFieldDropdown
              label="Tambahan Overtime (Jika Ada)"
              htmlFor="tambahan-waktu-overtime"
              value={selectedOvertime}
              data={overtime}
              onChange={(e) => {
                setSelectedOvertime(e.target.id);
              }}
              placeholder="Pilih Overtime"
            />

            <div className="detail-order__rent-detail-body__rent-date">
              <SelectFieldDate
                label="Tanggal Mulai"
                htmlFor="tanggal-mulai"
                name="tanggal-mulai"
                handleDaySelect={(date) => {
                  if (date) {
                    setStartRentDate(date);
                    setEndRentDate('');
                    setOrderData({
                      ...orderData,
                      order_detail: {
                        ...orderData.order_detail,
                        start_booking_date: format(date, 'yyyy-MM-dd').toString(),
                        vehicle_id: '',
                      },
                    });
                    setSelectedCar(undefined);
                    setVehicleInput('');
                  }
                }}
                selectedDay={startRentDate}
                value={startRentDate !== '' ? format(startRentDate, 'dd MMMM yyyy') : startRentDate}
                placeholder="Pilih Tanggal"
              />
              <SelectFieldTime
                label="Jam Mulai"
                htmlFor="jam-mulai"
                placeholder="00:00"
                value={orderData.order_detail.start_booking_time}
                onChange={(hour, minute) => {
                  setOrderData({
                    ...orderData,
                    order_detail: {
                      ...orderData.order_detail,
                      start_booking_time: `${hour}:${minute ? minute : '00'}`,
                      end_booking_time: `${hour}:${minute ? minute : '00'}`,
                      vehicle_id: '',
                    },
                  });
                  setSelectedOvertime('');
                  setSelectedCar(undefined);
                  setVehicleInput('');
                }}
                choosenDate={startRentDate}
                disable={startRentDate === '' ? true : false}
              />
            </div>

            <div className="detail-order__rent-detail-body__rent-date">
              <SelectFieldDate
                label="Tanggal Selesai"
                htmlFor="tanggal-selesai"
                name="tanggal-selesai"
                handleDaySelect={(date) => {
                  if (date) {
                    setEndRentDate(date);
                    setOrderData({
                      ...orderData,
                      order_detail: {
                        ...orderData.order_detail,
                        end_booking_date: format(date, 'yyyy-MM-dd').toString(),
                        vehicle_id: '',
                      },
                    });
                    setSelectedCar(undefined);
                    setVehicleInput('');
                  }
                }}
                selectedDay={endRentDate}
                fromDate={addDays(startRentDate === '' ? parseISO(startRentDate) : startRentDate, 1)}
                value={endRentDate !== '' ? format(endRentDate, 'dd MMMM yyyy') : endRentDate}
                placeholder="Pilih Tanggal"
                disable={startRentDate === '' ? true : false}
              />
              <SelectFieldTime
                label="Jam Selesai"
                htmlFor="jam-selesai"
                placeholder="00:00"
                value={orderData.order_detail.end_booking_time}
                onChange={(hour, minute) => {
                  setOrderData({
                    ...orderData,
                    order_detail: { ...orderData.order_detail, end_booking_time: `${hour}:${minute ? minute : '00'}` },
                    vehicle_id: '',
                  });
                  setSelectedCar(undefined);
                  setVehicleInput('');
                }}
                disable={startRentDate === '' ? true : false}
                startHour={orderData.order_detail.start_booking_time}
                showAllHours={true}
              />
            </div>

            <SelectFieldCar
              label="Jenis Mobil"
              htmlFor="pilih-mobil"
              placeholder="Cari Mobil.."
              data={vehicleList.vehicles}
              selectedCar={selectedCar}
              onSelectCar={setSelectedCar}
              value={vehicleInput}
              intersectionAction={intersectionAction}
              onChange={(e) => setVehicleInput(e.target.value)}
              onClick={(vehicleId, resetVehicle) => {
                if (vehicleId === '') {
                  setVehicleInput(resetVehicle);
                  return;
                }
                setVehicleInput(resetVehicle);
              }}
              payloadFilter={{
                locationRental: orderData?.order_detail?.location_id,
                startTrip: `${orderData?.order_detail?.start_booking_date} ${orderData?.order_detail?.start_booking_time}`,
                endTrip: `${orderData?.order_detail?.end_booking_date} ${orderData?.order_detail?.end_booking_time}`,
                supportDriver: false,
                customOrder: true,
                page: 0,
                limit: 0,
                order_id: orderData.transaction_key,
                subServiceId: rentalServicesData
                  ?.find((item) => item.name === 'Sewa Mobil')
                  ?.sub_services?.find((item) => item.name === 'Daily')
                  ?.facilities?.find((item) => item.name === 'Without Driver')?.id,
              }}
            />
            <SelectFieldDropdown
              label="Jumlah Penumpang"
              htmlFor="pilih-jumlah-penumpang"
              placeholder="Pilih Jumlah Penumpang"
              icon={<CarChair />}
              data={
                selectedCar
                  ? Array.from({ length: selectedCar?.max_passanger + 1 }, (_, idx) => idx + 1).map((item) => ({
                      id: item,
                      name: `${item} Orang`,
                    }))
                  : []
              }
              value={orderData.order_detail.passenger_number ?? ''}
              onChange={(e) =>
                setOrderData({
                  ...orderData,
                  order_detail: { ...orderData.order_detail, passenger_number: +e.target.id },
                })
              }
              disable={!selectedCar}
            />

            <div className="detail-order__rent-detail-body__rent-date">
              <InputField label="Kapasitas Koper" disabled value={selectedCar?.max_suitcase ?? '-'} />
              <InputField
                label="Koper yang dibawa"
                value={baggage ?? ''}
                onChange={(e) => setBaggage(isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value))}
              />
            </div>
          </div>
        </div>

        <div className="detail-order__price-detail">
          <div className="detail-order__price-detail-title">
            <h1>Rincian Biaya</h1>
          </div>
          <div className="detail-order__price-detail-body">
            <InputField label="Biaya Sewa Mobil" disabled value={setCurrency(summaryData?.booking_price) || '-'} />
            <InputField label="Deposit" disabled value={setCurrency(summaryData?.deposit) || '-'} />
            <InputField label="Deposit e-Toll" disabled value={setCurrency(summaryData?.deposit_e_toll) || '-'} />
            <InputField label="Overtime" disabled value={setCurrency(summaryData?.over_time_price) || '-'} />
            <InputField label="One Day Charge" disabled value={setCurrency(summaryData?.one_day_order_charge) || '-'} />
            <InputField
              label="Outside Operational Hour Charge"
              disabled
              value={setCurrency(summaryData?.outside_operational_charge) || '-'}
            />
            <InputField
              label="Exceed Max Passenger Charge"
              disabled
              value={setCurrency(summaryData?.exced_max_passenger_charge) || '-'}
            />
            <InputField
              label="Biaya Pengantaran"
              disabled
              value={setCurrency(summaryData?.rental_delivery_fee) || '-'}
            />
            <InputField
              label="Biaya Pengembalian"
              disabled
              value={setCurrency(summaryData?.rental_return_fee) || '-'}
            />
            <InputField
              label="Pembayaran Dengan Point"
              disabled
              value={summaryData?.point}
              icon={<PointsIcon />}
              iconPosition="start"
            />
            <InputField
              label="Tipe Pembayaran"
              disabled
              value={
                orderData.type === 'HALF'
                  ? `Pembayaran DP ${
                      orderData?.order_detail?.dp_rule?.formula_percentage?.value
                        ? `${orderData.order_detail.dp_rule.formula_percentage.value}%`
                        : ''
                    }`
                  : 'Pembayaran Full'
              }
            />
            {orderData.type === 'HALF' ? (
              <>
                <InputField
                  label={`Biaya Bayar DP ${
                    orderData?.order_detail?.dp_rule?.formula_percentage?.value
                      ? `${orderData.order_detail.dp_rule.formula_percentage.value}%`
                      : ''
                  }`}
                  disabled
                  value={setCurrency(summaryData.total_dp) || '-'}
                />
                <InputField
                  label="Biaya selanjutnya yang harus dibayar"
                  disabled
                  value={setCurrency(summaryData.remainder) || '-'}
                />
              </>
            ) : null}
          </div>
        </div>

        <div className="detail-order__price-detail-body__input-violation">
          <InputViolation
            violationList={violationList}
            setViolationList={setViolationList}
            totalPrice={violationPrice}
            isOnEdit={true}
            data={[]}
            violationPrice={0}
          />
        </div>

        <div className="detail-order__price-detail-body__input-additional">
          <InputAdditionals
            additionalList={additionalList}
            setAdditionalList={setAdditionalList}
            totalPrice={additionalPrice}
            isOnEdit={true}
            data={[]}
            additionalPrice={0}
          />
        </div>

        <div className="detail-order__total-price">
          <div className="total-price-wrapper">
            <h3>Total</h3>
            <p>{setCurrency(getTotalPrice(summaryData?.total_payment, orderData?.point, orderData?.vouchers))}</p>
          </div>
        </div>

        <div className="detail-order__transaction-buttons">
          <Button variant="outline" className="button btn-cancel" width={208} size="sm" onClick={backHandler}>
            Batal
          </Button>

          {checkPermission(offCanvasMenu, currentMenu, 'update') && (
            <Button className="button" width={208} size="sm" onClick={updateHandler}>
              Recalculate
            </Button>
          )}
        </div>
      </TableWrapper>
    </div>
  );
};

export default EditOrder;
