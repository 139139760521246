import React, { useEffect } from 'react';
import { useState } from 'react';
import { TableWrapper, Table, PaginationTable } from 'components/Global';
import { ReactComponent as PromoFilledIcon } from 'icons/promo-filled-icon.svg';
import { useSelector, useDispatch } from 'react-redux';
import { deletePromo, getAllPromo } from 'features/promo/actions';
import { getVehicleById } from 'features/vehicle/action';
import { useNavigate } from 'react-router-dom';
import { resetImagePromo, resetSelectedPromo } from 'features/promo/promoSlice';
import { useAppContext } from 'components/Context/AppContext';
import { format } from 'date-fns';

// COLUMN
const column = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Promo', value: 'name' },
  { header: 'Periode Mulai', value: 'start_promo_date' },
  { header: 'Periode Selesai', value: 'end_promo_date' },
  { header: 'Jenis Mobil', value: 'promo_vehicles' },
  { header: 'Harga Potongan', value: 'promo_price' },
];

const PromoList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setShowSpinner, showToast, setShowConfirmation } = useAppContext();
  const { data: promoData, isLoading: loadingPromoData } = useSelector((state) => state.promo);
  const { selected: selectedVehicle, isError: errorSelectedVehicle } = useSelector((state) => state.vehicle);

  const [currentPage, setCurrentPage] = useState(1);
  const [promoListData, setPromoListData] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [promoVechicleIdList, setPromoVechicleIdList] = useState([]);
  const [allVehicle, setAllVehicle] = useState([]);

  // FUNCTION TO GO TO EDIT PAGE
  const handleEdit = (e) => {
    const promoId = e.target.parentElement.parentElement.dataset['key'];

    dispatch(resetSelectedPromo());
    dispatch(resetImagePromo());
    navigate(`/promo/detail?tab=promo&promo_id=${promoId}`);
  };

  // FUNCTION TO DELETE PROMO
  const handleDelete = (e) => {
    const promoId = e.target.parentElement.parentElement.dataset['key'];

    setShowConfirmation({
      message: 'Apakah anda yakin ingin hapus promo',
      show: true,
      onClick: async () => {
        try {
          const res = await dispatch(deletePromo(promoId));

          if (res.meta?.requestStatus === 'fulfilled') {
            setShowSpinner(false);
            showToast({ type: 'success', message: 'Berhasil Hapus Promo' });
            dispatch(getAllPromo(currentPage));
            return;
          }
          showToast({ type: 'error', message: 'Gagal Hapus Promo' });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      },
    });
  };

  // GET ALL PROMO WHEN FIRST LOAD
  useEffect(() => {
    dispatch(getAllPromo(currentPage));
  }, [currentPage]);

  // GET ALL PROMO VEHICLES ID
  useEffect(() => {
    if (Object.keys(promoData).length === 0) return;

    let vehiclesIdArray = [];

    for (let child of promoData.data) {
      for (let vehicleChild of child.promotion_promo_value) {
        vehiclesIdArray.push(vehicleChild.product_id);
      }
    }

    const removeSameValueArray = [...new Set(vehiclesIdArray)];
    setPromoVechicleIdList(removeSameValueArray);
  }, [promoData]);

  // GET DETAIL VEHICLE FROM ALL VEHICLE IN PROMO DATA
  useEffect(() => {
    if (Object.keys(promoVechicleIdList).length === 0) return;

    if (isFirstLoad) {
      setIsFirstLoad(false);

      for (let child of promoVechicleIdList) {
        dispatch(getVehicleById(child));
      }
    }
  }, [promoVechicleIdList, isFirstLoad]);

  // SET SELECTED VEHICLE DATA OR PUSH IT TO PROMO VEHICLES DATA STATE
  useEffect(() => {
    if (Object.keys(selectedVehicle).length === 0) return;
    if (allVehicle.length >= promoVechicleIdList.length) return;

    setAllVehicle((prevState) => [...prevState, selectedVehicle]);
  }, [selectedVehicle]);

  // SET PROMO COLUMN WHEN PROMO DETAIL DATA CHANGING
  useEffect(() => {
    if (allVehicle.length === 0 || Object.keys(promoData).length === 0) return;

    let newPromoData = [];

    for (let childPromoData of promoData.data) {
      let vehiclesName = [];

      for (let childVehicle of childPromoData.promotion_promo_value) {
        if (vehiclesName.length === 0) {
          const foundedVehicle = allVehicle?.find((item) => item.id === childVehicle.product_id);
          vehiclesName.push({ name: foundedVehicle?.name, total: 1 });
        } else {
          const foundedVehicle = allVehicle?.find((item) => item.id === childVehicle.product_id);
          const existingVehicle = vehiclesName.find((item) => item.name === foundedVehicle?.name);
          if (existingVehicle) {
            existingVehicle.total += 1;
          } else {
            vehiclesName.push({ name: foundedVehicle?.name, total: 1 });
          }
        }
      }

      // new promo object
      const newItemPromo = {
        id: childPromoData.id,
        name: childPromoData.name,
        promo_vehicles:
          childPromoData.promotion_promo_value.length === 0 || !childPromoData.promotion_promo_value
            ? childPromoData.promotion_promo_value
            : vehiclesName,
        start_promo_date: format(new Date(childPromoData.start_date), 'dd/MM/yyyy'),
        end_promo_date: format(new Date(childPromoData.end_date), 'dd/MM/yyyy'),
        promo_price: childPromoData.value,
        value_type: childPromoData.value_type,
      };

      newPromoData.push(newItemPromo);
    }

    setPromoListData(newPromoData);
  }, [promoData, allVehicle]);

  // SHOW SPINNER WHEN LOADING
  useEffect(() => {
    if (loadingPromoData) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [loadingPromoData]);

  // SHOW TOAST ERROR WHEN ERROR
  useEffect(() => {
    if (errorSelectedVehicle) {
      showToast({ type: 'error', message: 'Terjadi Kesalahan' });
    }
  }, [errorSelectedVehicle]);

  return (
    <>
      {!promoData.data || promoData.data.length === 0 ? (
        <div className="message">Data Kosong</div>
      ) : (
        <TableWrapper icon={<PromoFilledIcon fill="#009EF7" width="25px" height="25px" />} title="Promo">
          <Table
            column={column}
            actionBtn
            onEdit={handleEdit}
            onDel={handleDelete}
            data={promoListData}
            currentPage={currentPage}
          />
          <PaginationTable
            currentPage={currentPage}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            totalCount={Object.keys(promoData).length === 0 ? 0 : promoData.pagination.total}
          />
        </TableWrapper>
      )}
    </>
  );
};

export default PromoList;
