import LoginPage from 'pages/LoginPage';
import { Routes, Route } from 'react-router-dom';
import DetailOrderComponent from 'components/HomeComponent/Content/DetailOrderComponent';
import TransactionDailyComponent from 'components/HomeComponent/Content/TransactionDailyComponent';
import PrivateRoutes from 'components/PrivateRoutes';
import DashboardComponent from 'components/HomeComponent/Content/DashboardComponent';
import WithDriver from 'components/HomeComponent/Content/WithDriver';
import AirportTransfer from 'components/HomeComponent/Content/AirportTransferComponent';
import Tour from 'components/HomeComponent/Content/Tour';
import VehicleComponent from 'components/HomeComponent/Content/VehicleComponent';
import CityComponent from 'components/HomeComponent/Content/CityComponent';
import DeliveryComponent from 'components/HomeComponent/Content/DeliveryComponent';
import AdditionalComponent from 'components/HomeComponent/Content/AdditionalComponent';
import DepositComponent from 'components/HomeComponent/Content/DepositComponent';
import ZoneComponent from 'components/HomeComponent/Content/ZoneComponent';
import GarageComponent from 'components/HomeComponent/Content/GarageComponent';
import PromoComponent from 'components/HomeComponent/Content/PromoComponent';
import BannerComponent from 'components/HomeComponent/Content/BannerComponent';
import AboutUsComponent from 'components/HomeComponent/Content/AboutUsComponent';
import ContactUsComponent from 'components/HomeComponent/Content/ContactUsComponent';
import TransactionRecapComponent from 'components/HomeComponent/Content/TransactionRecapComponent';
import TourComponent from 'components/HomeComponent/Content/TourComponent';
import CustomOrderComponent from 'components/HomeComponent/Content/CustomOrderComponent';
// import { useEffect } from 'react';
// import { useSelector } from 'react-redux';
import Missing from 'pages/Missing';
import CarBrandsComponent from 'components/HomeComponent/Content/CarBrandComponent';
import VehicleCategory from 'components/HomeComponent/Content/VehicleCategory';
import RolesComponent from 'components/HomeComponent/Content/RolesComponent';
import RolesAssignComponent from 'components/HomeComponent/Content/RolesAssignComponent';
import DetailOrderWithDriverComponent from 'components/HomeComponent/Content/DetailOrderWithDriverComponent';
import DetailOrderAirportTransfer from 'components/HomeComponent/Content/DetailOrderAirportTransferComponent';
import DetailSchedulePage from 'components/HomeComponent/Content/SchedulePriceComponent/DetailSchedulePage';
import DetailDepositOrder from 'components/HomeComponent/Content/DetailDepositComponent';
import PromoDetailComponent from 'components/HomeComponent/Content/PromoComponent/PromoDetailComponent';
import NotificationInboxPageComponent from 'components/HomeComponent/Content/NotificationComponent';
import NotificationComponent from 'components/HomeComponent/Content/NotificationTabComponent';
import { useDispatch } from 'react-redux';
import { messaging, requestPermission } from 'utils/firebase';
import { useEffect } from 'react';
import { setFirebaseToken } from 'features/notification/notifSlice';
import { onMessage } from 'firebase/messaging';
import TaskAdminComponent from 'components/HomeComponent/Content/TaskAdminComponent';
import TaskAdminDetail from 'components/HomeComponent/Content/TaskAdminComponent/TaskAdminDetail';
import VehicleDetailPage from 'components/HomeComponent/Content/VehicleComponent/VehicleDetail';
import LanguageComponent from 'components/HomeComponent/Content/LanguageComponent';
import TermAndConditionComponent from 'components/HomeComponent/Content/TermAndConditionComponent';
import DetailLokasi from 'components/HomeComponent/Content/TermAndConditionComponent/Lokasi/DetailLokasi';
import DetailService from 'components/HomeComponent/Content/TermAndConditionComponent/Service/DetailService';
import OvertimeRulesPage from 'components/HomeComponent/Content/OvertimeComponent/CreateOvertimeRules';
import CreateOvertimePage from 'components/HomeComponent/Content/OvertimeComponent/CreateOvertimePage';
import DownPaymentComponent from 'components/HomeComponent/Content/DownPaymentComponent';
import DownPaymentDetail from 'components/HomeComponent/Content/DownPaymentComponent/DownPaymentDetail';
import RoleDetail from 'components/HomeComponent/Content/RolesComponent/RoleDetail/RoleDetail';
import SchedulePriceAirportTransfer from 'components/HomeComponent/Content/SchedulePriceComponent/SchedulePriceAirport/CreateSchedulePriceAirport';
import AirportTransferCarComponent from 'components/HomeComponent/Content/AirportTransferCarComponent';
import DetailHarga from 'components/HomeComponent/Content/AirportTransferCarComponent/Harga/DetailHarga';
import OvertimeRulesList from 'components/HomeComponent/Content/OvertimeComponent/OvertimeRulesList';
import OvertimeList from 'components/HomeComponent/Content/OvertimeComponent/OvertimeList';
import SchedulePriceList from 'components/HomeComponent/Content/SchedulePriceComponent/SchedulePriceList';
import AirportTransferPriceList from 'components/HomeComponent/Content/SchedulePriceComponent/SchedulePriceAirport/AirportTransferList';
import AddUserInfluencer from 'components/HomeComponent/Content/UserComponent/AddUserInfluencer';
import UserListComponent from 'components/HomeComponent/Content/UserComponent/UserCustomer/UserList';
import UserInfluencerListComponent from 'components/HomeComponent/Content/UserComponent/UserInfluencer/InfluencerList';
import CustomerDetail from 'components/HomeComponent/Content/UserComponent/UserDetail/CustomerDetail';
import InfluencerDetail from 'components/HomeComponent/Content/UserComponent/DetailUserInfluencer/InfluencerDetail';
import MinimumRent from 'components/HomeComponent/Content/MinimumRent';
import CreateMinimumRentPage from 'components/HomeComponent/Content/MinimumRent/create-min-rent';

function App() {
  const dispatch = useDispatch();

  const getToken = async () => {
    const token = await requestPermission();
    dispatch(setFirebaseToken(token));
  };

  useEffect(() => {
    getToken();
    onMessage(messaging, (payload) => {
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: '/assets/icon/getride-graphics.svg',
      };

      if (Notification.permission === 'granted') {
        new Notification(notificationTitle, notificationOptions);
      }
    });
  }, []);

  return (
    <Routes>
      <Route path={'/login'} exact element={<LoginPage />} />
      <Route element={<PrivateRoutes />}>
        <Route path="/" element={<DashboardComponent />} />
        <Route path="/transaction-report" element={<TransactionRecapComponent />} />
        <Route path={'without-driver'}>
          <Route index={true} exact element={<TransactionDailyComponent />} />
          <Route path={'detail/:transactionKey'} exact element={<DetailOrderComponent />} />
          <Route path="custom-order" exact element={<CustomOrderComponent />} />
        </Route>
        <Route path="with-driver">
          <Route index={true} exact element={<WithDriver />} />
          <Route path={'detail/:transactionKey'} exact element={<DetailOrderWithDriverComponent />} />
          <Route path={'custom-order'} exact element={<CustomOrderComponent />} />
        </Route>
        <Route path="/airport-transfer">
          <Route index={true} exact element={<AirportTransfer />} />
          <Route path={'detail/:transactionKey'} exact element={<DetailOrderAirportTransfer />} />
          <Route path={'custom-order'} exact element={<CustomOrderComponent />} />
        </Route>
        <Route path="/tour" element={<Tour />} />
        <Route path="/user-customer">
          <Route index={true} exact element={<UserListComponent />} />
          <Route path="detail/:id" exact element={<CustomerDetail />} />
        </Route>
        <Route path="/user-influencer">
          <Route index={true} exact element={<UserInfluencerListComponent />} />
          <Route path="detail/:id" exact element={<InfluencerDetail />} />
          <Route path="add-item" exact element={<AddUserInfluencer />} />
        </Route>
        <Route path="/vehicles">
          <Route index exact element={<VehicleComponent />} />
          <Route path="add-vehicle" exact element={<VehicleDetailPage />} />
          <Route path="detail/:id" exact element={<VehicleDetailPage />} />
        </Route>
        <Route path="/airport-transfer-car">
          <Route index={true} exact element={<AirportTransferCarComponent />} />
          <Route path={'price'} exact element={<DetailHarga />} />
        </Route>
        <Route path="/tour-schedule" element={<TourComponent />} />
        <Route path="/rental-area" element={<CityComponent />} />
        <Route path="/return-and-delivery" element={<DeliveryComponent />} />
        <Route path="/garages" element={<GarageComponent />} />
        <Route path="/additional" element={<AdditionalComponent />} />
        <Route path="/deposit">
          <Route index={true} exact element={<DepositComponent />} />
          <Route path="detail/:transactionKey" exact element={<DetailDepositOrder />} />
        </Route>
        <Route path="/setting-dp">
          <Route index={true} exact element={<DownPaymentComponent />} />
          <Route path="detail" exact element={<DownPaymentDetail />} />
          <Route path="add-item" exact element={<DownPaymentDetail />} />
        </Route>
        <Route path="/zone" element={<ZoneComponent />} />
        <Route path="/language" element={<LanguageComponent />} />
        <Route path="/term-and-condition">
          <Route index={true} exact element={<TermAndConditionComponent />} />
          <Route path={'lokasi'} exact element={<DetailLokasi />} />
          <Route path={'service'} exact element={<DetailService />} />
        </Route>
        <Route path="/schedule-price">
          <Route index={true} exact element={<SchedulePriceList />} />
          <Route path={'add-item'} exact element={<DetailSchedulePage />} />
          <Route path={'detail'} exact element={<DetailSchedulePage />} />
        </Route>
        <Route path="/schedule-price-airport">
          <Route index={true} exact element={<AirportTransferPriceList />} />
          <Route path="add-schedule-price-at" exact element={<SchedulePriceAirportTransfer />} />
          <Route path="edit-schedule-price-at" exact element={<SchedulePriceAirportTransfer />} />
        </Route>
        <Route path={'promo'}>
          <Route index={true} exact element={<PromoComponent />} />
          <Route path={'add-item'} exact element={<PromoDetailComponent />} />
          <Route path={'detail'} exact element={<PromoDetailComponent />} />
        </Route>
        <Route path="/banner" element={<BannerComponent />} />
        <Route path="/selling-point" element={<AboutUsComponent />} />
        <Route path="/notification-inbox" element={<NotificationInboxPageComponent />} />
        <Route path="/notification" element={<NotificationComponent />} />
        <Route path="/contact-us" element={<ContactUsComponent />} />
        <Route path="/car-brands" element={<CarBrandsComponent />} />
        <Route path="/roles">
          <Route index={true} exact element={<RolesComponent />} />
          <Route path="detail" exact element={<RoleDetail />} />
        </Route>
        <Route path="/assign-roles" element={<RolesAssignComponent />} />
        <Route path="/category" element={<VehicleCategory />} />
        <Route path="/task-admin">
          <Route index={true} exact element={<TaskAdminComponent />} />
          <Route path={'detail/:transactionKey'} exact element={<TaskAdminDetail />} />
        </Route>
        <Route path="/overtime">
          <Route index={true} exact element={<OvertimeList />} />
          <Route path="create-overtime" exact element={<CreateOvertimePage />} />
          <Route path="edit-overtime" exact element={<CreateOvertimePage />} />
        </Route>
        <Route path="/overtime-rule">
          <Route index={true} exact element={<OvertimeRulesList />} />
          <Route path="create-rules" exact element={<OvertimeRulesPage />} />
          <Route path="edit-rules" exact element={<OvertimeRulesPage />} />
        </Route>
        <Route path="/min-rent">
          <Route index={true} exact element={<MinimumRent />} />
          <Route path="create-min-rent" exact element={<CreateMinimumRentPage />} />
          <Route path="edit-min-rent" exact element={<CreateMinimumRentPage />} />
        </Route>
      </Route>
      <Route path="*" element={<Missing />} />
    </Routes>
  );
}

export default App;
