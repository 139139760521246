import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getAllGarages = createAsyncThunk('garages/getAllGarages', async (_, thunkAPI) => {
  try {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const response = await axios
      .get(`${BASE_URL}/${API_VERSION}/garages`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => res);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const addGarages = createAsyncThunk('garages/addGarages', async (_, thunkAPI) => {
  try {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const response = await axios
      .post(
        `${BASE_URL}/${API_VERSION}/garages`,
        { ..._ },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      )
      .then((res) => res);

    thunkAPI.dispatch(getAllGarages());

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deleteGarages = createAsyncThunk('garages/deleteGarages', async (id, thunkAPI) => {
  try {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const response = await axios
      .delete(`${BASE_URL}/${API_VERSION}/garages/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => res);

    thunkAPI.dispatch(getAllGarages());

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateGarages = createAsyncThunk('garages/updateGarages', async ({ id, payload }, thunkAPI) => {
  try {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    await axios.put(
      `${BASE_URL}/${API_VERSION}/garages/${id}`,
      { ...payload },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    thunkAPI.dispatch(getAllGarages());
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getGarageDetail = createAsyncThunk('garages/getGarageDetail', async (id, thunkAPI) => {
  try {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const response = await axios
      .get(`${BASE_URL}/${API_VERSION}/garages/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => res);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
