import React from 'react';
import { InputField, SelectFieldDuration } from 'components/Global';
import { addDays, format } from 'date-fns';
import SelectFieldDate from 'components/Global/SelectFieldDate';
import SelectFieldTimeWithDriver from 'components/Global/SelectFieldTimeWithDriver';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import { ReactComponent as Calendar } from '../../../../../icons/calendar-icon.svg';

export const overtime = [
  {
    id: 'ot0',
    name: 'Tidak Ada Overtime',
    value: 0,
  },
  {
    id: 'ot1',
    name: '1 Jam',
    value: 1,
  },
  {
    id: 'ot2',
    name: '2 Jam',
    value: 2,
  },
  {
    id: 'ot3',
    name: '3 Jam',
    value: 3,
  },
  {
    id: 'ot4',
    name: '4 Jam',
    value: 4,
  },
  {
    id: 'ot5',
    name: '5 Jam',
    value: 5,
  },
  {
    id: 'ot6',
    name: '6 Jam',
    value: 6,
  },
];

const DetailHari = ({
  setStartRentDate,
  startRentDate,
  setOrderData,
  orderData,
  setDurationRent,
  durationRent,
  setDayDuration,
  dayDuration,
  setSelectedCar,
}) => {
  const isDisabledOvertimeOption = (startHour, endHour, startMinute, endMinute) => {
    if (endHour - startHour < 12) {
      if (endHour === 22) {
        if (startMinute > endMinute) {
          return false;
        }

        if (startMinute < endMinute) {
          return false;
        }
        return false;
      }

      return true;
    }

    if (endHour - startHour === 12 && endHour !== 22) {
      if (startMinute === endMinute) {
        return false;
      }

      if (startMinute > endMinute) {
        return true;
      }

      if (startMinute < endMinute) {
        return false;
      }
    }

    return false;
  };

  return (
    <div className="detail-order-with-driver__rent-detail">
      <div className="detail-order-with-driver__rent-detail-title">
        <h1>Detail Hari</h1>
      </div>
      <div className="detail-order-with-driver__rent-detail-body">
        <SelectFieldDate
          label="Tanggal Mulai"
          htmlFor="tanggal-mulai"
          name="tanggal-mulai"
          handleDaySelect={(date) => {
            if (date) {
              setStartRentDate(date);
              setOrderData({
                ...orderData,
                order_detail: {
                  ...orderData.order_detail,
                  start_booking_date: format(date, 'yyyy-MM-dd').toString(),
                  end_booking_date: durationRent
                    ? format(addDays(date, durationRent - 1), 'yyyy-MM-dd').toString()
                    : '',
                },
              });
              setSelectedCar(undefined);
            }
          }}
          selectedDay={startRentDate}
          value={startRentDate !== '' ? format(startRentDate, 'dd MMMM yyyy') : startRentDate}
          placeholder="Pilih Tanggal"
        />
        <SelectFieldDuration
          label="Durasi Sewa"
          htmlFor="durasi-sewa"
          placeholder="0 Hari"
          startDate={startRentDate}
          value={durationRent}
          onClick={(duration) => {
            setDurationRent(duration);
            setOrderData({
              ...orderData,
              order_detail: {
                ...orderData.order_detail,
                end_booking_date: format(addDays(startRentDate, duration - 1), 'yyyy-MM-dd').toString(),
              },
            });

            if (dayDuration.length !== 0) {
              const copyDayDuration = [...dayDuration];
              const slicedCopyDayDuration = copyDayDuration.slice(0, duration);
              setDayDuration(slicedCopyDayDuration);
            }
          }}
          disable={startRentDate ? false : true}
        />
      </div>

      <div className="detail-order-with-driver__rent-detail-body__day-duration">
        {Array.from({ length: durationRent }, (_, i) => i + 1).map((_, idx) => {
          const startTimeString = dayDuration[idx]?.booking_start_time;
          const endTimeString = dayDuration[idx]?.booking_end_time;

          const startHour = +startTimeString?.split(':')[0];
          const endHour = +endTimeString?.split(':')[0];
          const startMinute = +startTimeString?.split(':')[1];
          const endMinute = +endTimeString?.split(':')[1];

          const isDisabled = isDisabledOvertimeOption(startHour, endHour, startMinute, endMinute);

          return (
            <div key={idx} className="with-driver-form__form__day">
              <div className="with-driver-form__form__time">
                <InputField
                  disable={true}
                  label={`Day ${idx + 1}`}
                  icon={<Calendar />}
                  iconPosition="start"
                  value={(!isNaN(startRentDate) && format(addDays(startRentDate, idx), 'dd-MM-yyyy')) || ''}
                  readOnly
                />
                {/* OVERTIME */}
                <SelectFieldDropdown
                  label="Tambahan Overtime (Jika Ada)"
                  htmlFor="tambahan-waktu-overtime"
                  value={isDisabled ? 'ot0' : dayDuration[idx]?.overtime_duration || ''}
                  data={overtime}
                  onChange={(e) => {
                    setDayDuration((prev) =>
                      prev[idx] === undefined
                        ? [...prev, { ...prev[idx], overtime_duration: e.target.id }]
                        : prev.map((item, day) =>
                            day === idx ? { ...prev[idx], overtime_duration: e.target.id } : item,
                          ),
                    );
                  }}
                  placeholder="Pilih Overtime"
                  disable={isDisabled}
                />
              </div>

              {/* JAM MULAI & JAM SELESAI */}
              <div className="with-driver-form__form__time">
                <SelectFieldTimeWithDriver
                  label="Jam Mulai"
                  htmlFor="jam-mulai"
                  placeholder="00:00"
                  value={dayDuration[idx]?.booking_start_time || ''}
                  onChange={(hour, minute) => {
                    if (idx == 0) {
                      setOrderData({
                        ...orderData,
                        order_detail: {
                          ...orderData.order_detail,
                          start_booking_time: `${hour}:${minute ? minute : '00'}`,
                        },
                      });
                    }
                    setDayDuration((prev) =>
                      prev[idx] === undefined
                        ? [...prev, { ...prev[idx], booking_start_time: `${hour}:${minute ? minute : '00'}` }]
                        : prev.map((item, day) =>
                            day === idx
                              ? {
                                  ...prev[idx],
                                  booking_start_time: `${hour}:${minute ? minute : '00'}`,
                                  booking_end_time:
                                    // if end time less than start time, set empty
                                    +prev[idx].booking_end_time?.split(':')[0] <= hour
                                      ? ''
                                      : // if end time - start time greater than 12, set end time 12 hour margin from start time
                                      +prev[idx].booking_end_time?.split(':')[0] - hour > 12
                                      ? `${
                                          +prev[idx].booking_end_time?.split(':')[0] -
                                          (+prev[idx].booking_end_time?.split(':')[0] - hour - 12)
                                        }:${minute}`
                                      : // if end time - start time equal 12, set minute same with start time
                                      +prev[idx].booking_end_time?.split(':')[0] - hour === 12
                                      ? `${prev[idx].booking_end_time?.split(':')[0]}:00`
                                      : // else end time not changed
                                        prev[idx].booking_end_time,
                                }
                              : item,
                          ),
                    );
                  }}
                  disable={startRentDate === '' ? true : false}
                />
                <SelectFieldTimeWithDriver
                  label="Jam Selesai"
                  htmlFor="jam-selesai"
                  placeholder="00:00"
                  value={dayDuration[idx]?.booking_end_time || ''}
                  onChange={(hour, minute) => {
                    if (idx == 0) {
                      setOrderData({
                        ...orderData,
                        order_detail: {
                          ...orderData.order_detail,
                          end_booking_time: `${hour}:${minute ? minute : '00'}`,
                        },
                      });
                    }
                    setDayDuration((prev) =>
                      prev[idx] === undefined
                        ? [...prev, { ...prev[idx], booking_end_time: `${hour}:${minute ? minute : '00'}` }]
                        : prev.map((item, day) =>
                            day === idx
                              ? { ...prev[idx], booking_end_time: `${hour}:${minute ? minute : '00'}` }
                              : item,
                          ),
                    );
                  }}
                  disable={startRentDate === '' ? true : false}
                  startTime={dayDuration[idx]?.booking_start_time}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DetailHari;
