import { useEffect, useState } from 'react';
import { getVehicleById } from 'features/vehicle/action';
import { useDispatch } from 'react-redux';
import { indonesianDateFormat } from './helpers';
import { getAirportPackageById } from 'features/orders/actions';

const useCreateTableData = (data = []) => {
  const dispatch = useDispatch();

  const [vehicleData, setVehicleData] = useState([]);
  const [airportVehicleType, setAirportVehicleType] = useState([]);

  const getCarName = (id) => {
    const selectedData = vehicleData?.filter((item) => item?.id === id);
    const isDataEmpty = selectedData?.length === 0 || selectedData === undefined;

    if (isDataEmpty) return '-';
    return selectedData[0]?.name;
  };

  const getAirportTypeTitle = (id) => {
    const selectedData = airportVehicleType?.filter((item) => item?.id === id);
    const isDataEmpty = selectedData?.length === 0 || selectedData === undefined;

    if (isDataEmpty) return '-';
    return selectedData[0]?.title;
  };

  useEffect(() => {
    const getVehicleOrder = async () => {
      if (data?.length > 0) {
        if (data[0].order_type_id === 2) return;
        const vehiclesIdToFetch = [];

        data.forEach((item) => {
          if (vehiclesIdToFetch.includes(item.order_detail.vehicle_id)) {
            return;
          } else {
            vehiclesIdToFetch.push(item.order_detail.vehicle_id);
          }
        });

        const promises = vehiclesIdToFetch.map((vehicleId) => dispatch(getVehicleById(vehicleId)).unwrap());

        const results = await Promise.allSettled(promises);
        const fulfilledResults = results
          .filter((result) => result.status === 'fulfilled')
          .map((vehicle) => vehicle.value);
        setVehicleData(fulfilledResults);
      }
    };

    const getAirportTransferVehicleOrder = async () => {
      if (data?.length > 0) {
        if (data[0].order_type_id !== 2) return;
        const airportTransferIdToFetch = [];

        data.forEach((item) => {
          airportTransferIdToFetch.push({
            id: item.airport_transfer_package_id,
            pickupTrip: `${item?.order_detail?.start_booking_date} ${item?.order_detail?.start_booking_time.slice(
              0,
              5,
            )}`,
          });
        });

        const promises = airportTransferIdToFetch.map((item) =>
          dispatch(getAirportPackageById({ id: item.id, pickupTrip: item.pickupTrip })).unwrap(),
        );

        const results = await Promise.allSettled(promises);
        const fulfilledResults = results.filter((result) => result.status === 'fulfilled').map((item) => item.value);
        setAirportVehicleType(fulfilledResults);
      }
    };

    getVehicleOrder();
    getAirportTransferVehicleOrder();
  }, [data]);

  const tableData = data.map((item) => ({
    id: item.id,
    name: item.user_name,
    car_type: getCarName(item.order_detail.vehicle_id),
    package_type: getAirportTypeTitle(item.airport_transfer_package_id),
    start_date: indonesianDateFormat(item.order_detail.start_booking_date),
    end_date: indonesianDateFormat(item.order_detail.end_booking_date),
    start_time: item.order_detail.start_booking_time?.slice(0, 5),
    total_payment: item.total_payment,
    status: item?.order_approval_status ? 'waiting_approval' : item.order_status.toLowerCase(),
    status_cancelation: item.order_cancelation?.Status.toLowerCase(),
    rent_status: 'Perpanjang Sewa',
    deposit_status: item.is_deposit_exists,
    deposit: item?.deposit || item.total_payment / 2,
    type: item?.type === 'FULL' ? 'FULL' : item?.payment_time !== null ? 'FULL' : 'HALF',
    order_approval_status: item?.order_approval_status || '',
  }));

  return { data: tableData };
};

export default useCreateTableData;
