/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as AirportIcon } from 'icons/airport-transfer-filled.svg';
import { ReactComponent as PointsIcon } from 'icons/points-icon.svg';
import { Button, InputField, TableWrapper } from 'components/Global';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { indonesianDateFormat } from 'utils/helpers';
import { checkEmptyObject, setCurrency } from 'utils/functionality';
import countryCodes from 'utils/country-codes.json';
import clsx from 'clsx';
import { getCustomerData } from 'features/user/slice';
import {
  changeOrderStatus,
  fetchOrderByIdWithApproval,
  getAirportPackageById,
  getVehicleAirportTransferPackage,
} from 'features/orders/actions';
import { getAllCouriers, getDetailTaskCourier } from 'features/couriers/actions';
import SelectFieldAirportTransferCar from 'components/Global/SelectFieldAirportTransferCar';
import { downloadOrderDetail } from 'features/order-detail-pdf/actions';
import TextAreaInput from 'components/Global/TextArea';
import { getLocationTimezone } from 'utils/getTimezone';
import { getPaymentMethodString } from 'utils/getPaymentMethod';
import { getVehicleById } from 'features/vehicle/action';
import BankTransferConfirmation from './BankTransferConfirmation';
import { getAllRentalLocation } from 'features/rental-location/actions';
import Tabs, { TabPane } from 'components/Global/Tabs';
import ReactDOM from 'react-dom';
import PriceDifferenceModal from 'components/Modals/PriceDifferenceModal';

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES;

const DetailOrder = ({ orderData: data, getFileName, imagePreviewHandler, transactionKey }) => {
  const { showToast, setShowRejectOrderModal, setShowSpinner, setShowConfirmation } = useAppContext();

  const [selectedCountry, setSelectedCountry] = useState({
    name: '',
    code: '',
  });
  const [activeTab, setActiveTab] = useState('Data Sekarang');
  const [showBankTransferConfirmation, setShowBankTransferConfirmation] = useState(false);
  const [vehicleInput, setVehicleInput] = useState('');
  const [isPaymentValid, setIsPaymentValid] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [assignedCourier, setAssignedCourier] = useState(0);
  const [selectedCar, setSelectedCar] = useState(undefined);
  const [allVehicle, setAllVehicle] = useState([]);
  const [oldOrderData, setOldOrderData] = useState({});
  const [newOrderData, setNewOrderData] = useState({});
  const [showPriceDifference, setShowPriceDifference] = useState(false);

  const customer = useSelector(getCustomerData);
  const vehicles = useSelector((state) => state.airportTransferOrder.airportTransferCar);
  const airportPackageVehicle = useSelector((state) => state.airportTransferOrder.selected);
  const vehicleById = useSelector((state) => state.vehicle.selected);
  const couriers = useSelector((state) => state.couriers.data);
  // const rentalLocation = useSelector((state) => state.rentalLocation.data);
  const courierTask = useSelector((state) => state.courierTask?.selectedCourierTasks);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const findCountryByCode = (code) => countryCodes.find((item) => item.dial_code == code);

  useEffect(() => {
    dispatch(getAllCouriers());
    dispatch(getAllRentalLocation());
  }, []);

  useEffect(() => {
    dispatch(fetchOrderByIdWithApproval(transactionKey));
  }, [transactionKey]);

  useEffect(() => {
    if (checkEmptyObject(data)) return;
    setOldOrderData(data || {});
    setNewOrderData(data?.approval?.data || {});
  }, [data]);

  useEffect(() => {
    if (!Object.prototype.hasOwnProperty.call(data, 'order_detail')) return;
    if (!transactionKey) return;
    if (data.order_status === 'COMPLETED' || data.order_status === 'FINISHED') {
      dispatch(getDetailTaskCourier(data.transaction_key));
    }

    dispatch(
      getAirportPackageById({
        id: data.airport_transfer_package_id,
        pickupTrip: `${data.order_detail?.start_booking_date} ${data.order_detail?.start_booking_time.slice(0, 5)}`,
      }),
    );
    dispatch(
      getVehicleAirportTransferPackage({
        id: data.airport_transfer_package_id,
        locationRental: data.order_detail.location_id,
        pickupTrip: `${data.order_detail?.start_booking_date} ${data.order_detail?.start_booking_time.slice(0, 5)}`,
        page: 1,
      }),
    );
    dispatch(getVehicleById(data.order_detail.vehicle_id));
  }, [data]);

  useEffect(() => {
    if (Object.keys(vehicles).length === 0 || !vehicles.vehicles) return;
    setAllVehicle(
      [...vehicles.vehicles].map((item) => ({
        ...item,
        vehicle_image: item.images ? item.images[item.images.length - 1] : '',
      })),
    );
  }, [vehicles]);

  useEffect(() => {
    if (customer) {
      setSelectedCountry(findCountryByCode(data?.is_admin_creation ? data?.phone_country_code : customer?.phone_code));
    }
  }, [customer, data]);

  useEffect(() => {
    if (Object.keys(courierTask).length > 0) {
      setAssignedCourier(+courierTask?.order?.courier_task?.courier_id);
    }
    setSelectedCar(() => allVehicle?.find((item) => item?.vehicle_id === data.order_detail?.vehicle_id));
  }, [courierTask, allVehicle]);

  const handleProcess = () => {
    let prevStatus;
    let nextStatus;
    let confirmationMessage;
    let toastSuccessMessage;
    let toastErrorMessage;

    switch (data?.order_status) {
      case 'CHECKOUT':
        prevStatus = 'CHECKOUT';
        nextStatus = 'PAID';
        confirmationMessage = 'Apakah anda yakin ingin mengkonfirmasi transaksi?';
        toastSuccessMessage = 'Transaksi Berhasil Dikonfirmasi';
        toastErrorMessage = 'Transaksi Gagal Dikonfirmasi!';
        break;
      case 'PAID':
        prevStatus = 'PAID';
        nextStatus = 'COMPLETED';
        confirmationMessage = 'Apakah anda yakin ingin memproses transaksi?';
        toastSuccessMessage = 'Transaksi Berhasil Diproses';
        toastErrorMessage = 'Transaksi Gagal Diproses!';
        break;
      default:
        throw new Error('order status not found!');
    }

    if (data?.order_status === 'PAID') {
      // if (!assignedCourier) {
      //   showToast({ type: 'error', message: 'Pilih Driver' });
      //   return;
      // }
      if (!selectedCar) {
        showToast({ type: 'error', message: 'Pilih Mobil' });
        return;
      }
    }

    const payload = {
      prevStatus,
      nextStatus,
      transaction_key: data?.transaction_key,
      airport_package_vehicle_id: selectedCar?.vehicle_id,
      courier_id: +assignedCourier,
    };

    setShowConfirmation({
      message: confirmationMessage,
      show: true,
      onClick: async () => {
        try {
          await dispatch(changeOrderStatus(payload)).unwrap();
          showToast({ type: 'success', message: toastSuccessMessage });
        } catch (err) {
          showToast({ type: 'error', message: toastErrorMessage });
        } finally {
          navigate(-1);
        }
      },
    });
  };

  const backHandler = () => {
    navigate(-1);
  };

  const handleExportToPdf = async () => {
    try {
      await dispatch(downloadOrderDetail(data?.transaction_key)).unwrap();
    } catch (error) {
      showToast({ type: 'error', message: 'Gagal Export ke PDF' });
    }
  };

  const timezone = !data?.order_detail?.loc_time_id ? '' : getLocationTimezone(data?.order_detail?.loc_time_id);

  return (
    <>
      {showBankTransferConfirmation ? (
        <BankTransferConfirmation
          getFileName={getFileName}
          imagePreviewHandler={imagePreviewHandler}
          orderData={data}
          setShowBankTransferConfirmation={setShowBankTransferConfirmation}
          setIsPaymentValid={setIsPaymentValid}
          isPaymentValid={isPaymentValid}
          handleProcess={handleProcess}
        />
      ) : (
        <div className="detail-order-airport">
          <div className="detail-order-airport__back-btn" onClick={backHandler}>
            <LeftArrow />
            <p>Kembali</p>
          </div>
          <TableWrapper
            icon={<AirportIcon fill="#009EF7" width="25px" height="25px" />}
            title="Airport Transfer"
            showExportButton
            onExportToPdf={handleExportToPdf}
            CustomFilterComponent={
              Object.prototype.hasOwnProperty.call(data, 'order_approval_history') ? (
                <div className="btn-export">
                  <Button variant="primary" onClick={() => setShowPriceDifference(true)}>
                    Detail Perbedaan
                  </Button>
                </div>
              ) : null
            }
          >
            {(data?.order_status === 'PAID' ||
              data?.order_status === 'COMPLETED' ||
              data?.order_status === 'FINISHED') &&
              !data.order_approval_status && (
                <div className="detail-order-airport__selection">
                  {/* SELECT DRIVER */}
                  {/* <SelectFieldDropdown
              type="select"
              data={couriers.data}
              value={assignedCourier}
              placeholder="Pilih Driver"
              onChange={(e) => setAssignedCourier(e.target.id)}
              label="Assign To"
              htmlFor="assign-to"
              className="detail-order-airport__select-driver"
              disable={data.order_status !== 'PAID'}
              /> */}

                  {/* SELECT CAR */}
                  <SelectFieldAirportTransferCar
                    label="Pilih Mobil"
                    htmlFor="pilih-mobil"
                    placeholder="Cari Mobil.."
                    data={allVehicle}
                    selectedCar={selectedCar}
                    onSelectCar={setSelectedCar}
                    value={vehicleInput}
                    onChange={(e) => setVehicleInput(e.target.value)}
                    onClick={(vehicleId, resetVehicle) => {
                      if (vehicleId === '') {
                        setVehicleInput(resetVehicle);
                        return;
                      }
                      dispatch(getVehicleById(vehicleId));
                      setVehicleInput(resetVehicle);
                    }}
                    disable={data.order_status !== 'PAID'}
                  />
                </div>
              )}
            {data?.order_approval_status?.includes('UPDATE_ORDER') ? (
              <Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
                <TabPane label="Data Sekarang">
                  <div className="detail-order-airport__renter-detail">
                    <div className="detail-order-airport__renter-detail-title">
                      <h1>Detail Penyewa</h1>
                    </div>
                    <div className="detail-order-airport__renter-detail-body">
                      <InputField label="Nama Lengkap" disabled value={newOrderData?.user_name || '-'} />
                      <InputField label="Email" disabled value={newOrderData?.email || '-'} />

                      <div className="detail-order-airport__renter-detail-body__phone-number">
                        <InputField
                          label="No Handphone"
                          disabled
                          value={
                            newOrderData?.is_admin_creation
                              ? newOrderData?.phone_country_code
                              : customer?.phone_code || '-'
                          }
                          icon={
                            <img
                              src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                              width={26}
                              height={16}
                              alt={selectedCountry?.name + ' nation flag'}
                            />
                          }
                        />
                        <InputField value={newOrderData?.phone_number || '-'} disabled />
                      </div>

                      <InputField label="Negara" disabled value={selectedCountry?.name || '-'} />

                      <div className="detail-order-airport__renter-detail-body__phone-number">
                        <div className="detail-order-airport__renter-detail-body__phone-number-wrapper">
                          <InputField
                            label="Whatsapp"
                            disabled
                            value={
                              newOrderData?.is_admin_creation
                                ? newOrderData?.phone_country_code
                                : customer?.phone_code || '-'
                            }
                            icon={
                              <img
                                src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                                width={26}
                                height={16}
                                alt={selectedCountry?.name + ' nation flag'}
                              />
                            }
                          />
                          <div className="detail-order-airport__renter-detail-body__image-wrapper"></div>
                        </div>
                        <InputField disabled value={newOrderData?.wa_number || '-'} />
                      </div>
                    </div>

                    <div className="detail-order-airport__identity-detail-body">
                      <div className="detail-order-airport__identity-detail-body__preview-image">
                        <InputField
                          label="KTP"
                          disabled
                          value={
                            newOrderData?.is_admin_creation &&
                            getFileName(newOrderData?.order_detail?.identity?.ktp) !== ''
                              ? getFileName(newOrderData?.order_detail?.identity?.ktp)
                              : customer?.PersonalInfos?.ktp
                              ? getFileName(customer?.PersonalInfos?.ktp)
                              : 'Belum Upload KTP'
                          }
                          className="preview-image-input"
                        />
                        {(newOrderData?.is_admin_creation &&
                          getFileName(newOrderData?.order_detail?.identity?.ktp) !== '') ||
                        customer?.PersonalInfos?.ktp ? (
                          <Button
                            bgColor="#D9D9D9"
                            textColor="#000000"
                            className="preview-image-btn"
                            onClick={() =>
                              imagePreviewHandler(
                                newOrderData?.is_admin_creation
                                  ? newOrderData?.order_detail?.identity?.ktp
                                  : IMAGE_BASE_URL + customer?.PersonalInfos?.ktp,
                              )
                            }
                          >
                            Lihat
                          </Button>
                        ) : null}
                      </div>

                      <div className="detail-order-airport__identity-detail-body__preview-image">
                        <InputField
                          label="SIM"
                          disabled
                          value={
                            newOrderData?.is_admin_creation &&
                            getFileName(newOrderData?.order_detail?.identity?.sim) !== ''
                              ? getFileName(newOrderData?.order_detail?.identity?.sim)
                              : customer?.PersonalInfos?.sim
                              ? getFileName(customer?.PersonalInfos?.sim)
                              : 'Belum Upload SIM'
                          }
                          className="preview-image-input"
                        />
                        {(newOrderData?.is_admin_creation &&
                          getFileName(newOrderData?.order_detail?.identity?.sim) !== '') ||
                        customer?.PersonalInfos?.sim ? (
                          <Button
                            bgColor="#D9D9D9"
                            textColor="#000000"
                            className="preview-image-btn"
                            onClick={() =>
                              imagePreviewHandler(
                                newOrderData?.is_admin_creation
                                  ? newOrderData?.order_detail?.identity?.sim
                                  : IMAGE_BASE_URL + customer?.PersonalInfos?.sim,
                              )
                            }
                          >
                            Lihat
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="detail-order-airport__rent-detail">
                    <div className="detail-order-airport__rent-detail-title">
                      <h1>Detail Order</h1>
                    </div>
                    <div className="detail-order-airport__rent-detail-body">
                      <InputField
                        label="Lokasi Penjemputan"
                        disabled
                        value={newOrderData?.order_detail?.rental_delivery_location || '-'}
                      />
                      <InputField
                        label="Lokasi Pengantaran"
                        disabled
                        value={newOrderData?.order_detail?.rental_return_location || '-'}
                      />
                      <InputField
                        label="Detail Lokasi Penjemputan"
                        disabled
                        value={newOrderData?.order_detail?.rental_delivery_location_detail || '-'}
                      />
                      <InputField
                        label="Detail Lokasi Pengantaran"
                        disabled
                        value={newOrderData?.order_detail?.rental_return_location_detail || '-'}
                      />
                      <div className="detail-order__rent-detail-body__rent-date">
                        <InputField
                          label="Tanggal"
                          disabled
                          value={
                            newOrderData?.order_detail?.start_booking_date
                              ? indonesianDateFormat(newOrderData?.order_detail?.start_booking_date)
                              : '-'
                          }
                        />
                        <InputField
                          label="Jam"
                          disabled
                          value={(newOrderData?.order_detail?.start_booking_time || '-') + ' ' + timezone}
                        />
                      </div>
                      <InputField
                        label="Nomor Penerbangan"
                        disabled
                        value={newOrderData?.order_detail?.flight_number || '-'}
                      />
                      <TextAreaInput
                        style={{
                          height:
                            newOrderData?.airport_package_vehicle_type?.split(', ').length >= 10
                              ? 96
                              : newOrderData?.airport_package_vehicle_type?.split(', ').length >= 5
                              ? 44 * 2
                              : 44,
                        }}
                        label="Jenis Mobil"
                        disabled
                        value={newOrderData?.airport_package_vehicle_type ?? '-'}
                        className="text-area-car-package"
                      />

                      <div className="detail-order__rent-detail-body__rent-date">
                        <InputField
                          label="Kapasitas Koper"
                          disabled
                          value={
                            newOrderData?.order_status === 'PENDING' || newOrderData?.order_status === 'CHECKOUT'
                              ? airportPackageVehicle?.max_suitecase
                              : newOrderData?.order_status === 'PAID' && !selectedCar
                              ? airportPackageVehicle?.max_suitecase
                              : vehicleById?.max_suitcase ?? '-'
                          }
                        />
                        <InputField
                          label="Koper yang dibawa"
                          disabled
                          value={newOrderData?.order_detail?.baggage ?? 0}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="detail-order-airport__price-detail">
                    <div className="detail-order-airport__price-detail-title">
                      <h1>Rincian Biaya</h1>
                    </div>

                    <div className="detail-order-airport__price-detail-body">
                      <InputField
                        label="Biaya Sewa Mobil"
                        disabled
                        value={setCurrency(newOrderData?.booking_price) || '-'}
                      />
                      <InputField
                        label="Biaya Pengantaran"
                        disabled
                        value={setCurrency(newOrderData?.rental_delivery_fee) || '-'}
                      />
                      <InputField
                        label="Outside Operational Hour Charge"
                        disabled
                        value={setCurrency(newOrderData?.outside_operational_charge) || '-'}
                      />
                      <InputField
                        label="Pembayaran Dengan Point"
                        disabled
                        value={newOrderData?.point}
                        icon={<PointsIcon />}
                        iconPosition="start"
                      />
                      <InputField
                        label="Charge Payment Gateway Platform"
                        disabled
                        value={setCurrency(newOrderData?.disbursement?.total_amount_external_payment_fee) || '-'}
                      />
                      <InputField
                        label="Customer Service Platform"
                        disabled
                        value={setCurrency(newOrderData?.disbursement?.total_amount_customer_service_fee) || '-'}
                      />
                      <InputField
                        label="Total Keseluruhan"
                        disabled
                        value={setCurrency(newOrderData?.total_payment) || '-'}
                      />
                    </div>
                  </div>

                  <div className="detail-order__voucher-detail">
                    <div className="detail-order__voucher-detail-title">
                      <h1>Voucher</h1>
                    </div>
                    <div className="detail-order__voucher-detail-body">
                      <InputField label="Nama Voucher" disabled value={newOrderData?.vouchers?.[0].name || '-'} />
                      <InputField label="Kode Voucher" disabled value={newOrderData?.vouchers?.[0].code || '-'} />
                      <InputField
                        label="Harga Potongan"
                        disabled
                        value={setCurrency(newOrderData?.vouchers?.[0].value) || '-'}
                      />
                    </div>
                  </div>

                  <div className="detail-order-airport__payment-detail">
                    <div className="detail-order-airport__payment-detail-title">
                      <h1>Pembayaran</h1>
                    </div>
                    <div className="detail-order-airport__payment-detail-body">
                      <InputField label="Status Pembayaran" disabled value={data?.order_status || '-'} />

                      <div className={clsx('detail-order-airport__payment-detail-body__payment-method confirmation')}>
                        <InputField
                          label="Metode Pembayaran"
                          disabled
                          // value={data?.disbursement?.payment?.method ? data?.disbursement.payment.method : '-'}
                          value={getPaymentMethodString(data?.disbursement_recalculate)}
                        />
                        {data.order_status === 'CHECKOUT' &&
                        data?.order_approval_status !== 'WAITING_APPROVAL_DELETE_ORDER' ? (
                          <Button
                            height={44}
                            onClick={() => setShowBankTransferConfirmation(true)}
                            disabled={isPaymentValid}
                          >
                            Konfirmasi
                          </Button>
                        ) : null}
                      </div>

                      {data.order_status !== 'CHECKOUT' &&
                      data.order_status !== 'RECONFIRMATION' &&
                      Object.prototype.hasOwnProperty.call(data, 'disbursement') ? (
                        <>
                          <InputField
                            label="Nama Rekening"
                            disabled
                            value={
                              Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                                ? [...data.disbursement_reconfirmation].pop()?.sender_name
                                : data?.disbursement?.sender_name || '-'
                            }
                          />

                          <InputField
                            label="Nama Bank"
                            disabled
                            value={
                              Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                                ? [...data.disbursement_reconfirmation].pop()?.sender_bank_name
                                : data?.disbursement?.sender_bank_name || '-'
                            }
                          />

                          <div className="detail-order__payment-detail-body__preview-image">
                            <InputField
                              label="Foto Bukti Transfer"
                              disabled
                              value={getFileName(
                                Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                                  ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                                  : data?.disbursement?.disbursement_confirmation_image,
                              )}
                              className="preview-image-input"
                            />

                            <Button
                              bgColor="#D9D9D9"
                              textColor="#000000"
                              className="preview-image-btn"
                              onClick={() =>
                                imagePreviewHandler(
                                  Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                                    ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                                    : data?.disbursement?.disbursement_confirmation_image,
                                )
                              }
                            >
                              Lihat
                            </Button>
                          </div>
                          <div />
                        </>
                      ) : null}
                    </div>
                  </div>

                  <div className="detail-order-airport__transaction-buttons">
                    <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
                      Kembali
                    </Button>
                  </div>
                </TabPane>
                <TabPane label="Data Sebelum">
                  <div className="detail-order-airport__renter-detail">
                    <div className="detail-order-airport__renter-detail-title">
                      <h1>Detail Penyewa</h1>
                    </div>
                    <div className="detail-order-airport__renter-detail-body">
                      <InputField label="Nama Lengkap" disabled value={oldOrderData?.user_name || '-'} />
                      <InputField label="Email" disabled value={oldOrderData?.email || '-'} />

                      <div className="detail-order-airport__renter-detail-body__phone-number">
                        <InputField
                          label="No Handphone"
                          disabled
                          value={
                            oldOrderData?.is_admin_creation
                              ? oldOrderData?.phone_country_code
                              : customer?.phone_code || '-'
                          }
                          icon={
                            <img
                              src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                              width={26}
                              height={16}
                              alt={selectedCountry?.name + ' nation flag'}
                            />
                          }
                        />
                        <InputField value={oldOrderData?.phone_number || '-'} disabled />
                      </div>

                      <InputField label="Negara" disabled value={selectedCountry?.name || '-'} />

                      <div className="detail-order-airport__renter-detail-body__phone-number">
                        <div className="detail-order-airport__renter-detail-body__phone-number-wrapper">
                          <InputField
                            label="Whatsapp"
                            disabled
                            value={
                              oldOrderData?.is_admin_creation
                                ? oldOrderData?.phone_country_code
                                : customer?.phone_code || '-'
                            }
                            icon={
                              <img
                                src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                                width={26}
                                height={16}
                                alt={selectedCountry?.name + ' nation flag'}
                              />
                            }
                          />
                          <div className="detail-order-airport__renter-detail-body__image-wrapper"></div>
                        </div>
                        <InputField disabled value={oldOrderData?.wa_number || '-'} />
                      </div>
                    </div>

                    <div className="detail-order-airport__identity-detail-body">
                      <div className="detail-order-airport__identity-detail-body__preview-image">
                        <InputField
                          label="KTP"
                          disabled
                          value={
                            oldOrderData?.is_admin_creation &&
                            getFileName(oldOrderData?.order_detail?.identity?.ktp) !== ''
                              ? getFileName(oldOrderData?.order_detail?.identity?.ktp)
                              : customer?.PersonalInfos?.ktp
                              ? getFileName(customer?.PersonalInfos?.ktp)
                              : 'Belum Upload KTP'
                          }
                          className="preview-image-input"
                        />
                        {(oldOrderData?.is_admin_creation &&
                          getFileName(oldOrderData?.order_detail?.identity?.ktp) !== '') ||
                        customer?.PersonalInfos?.ktp ? (
                          <Button
                            bgColor="#D9D9D9"
                            textColor="#000000"
                            className="preview-image-btn"
                            onClick={() =>
                              imagePreviewHandler(
                                oldOrderData?.is_admin_creation
                                  ? oldOrderData?.order_detail?.identity?.ktp
                                  : IMAGE_BASE_URL + customer?.PersonalInfos?.ktp,
                              )
                            }
                          >
                            Lihat
                          </Button>
                        ) : null}
                      </div>

                      <div className="detail-order-airport__identity-detail-body__preview-image">
                        <InputField
                          label="SIM"
                          disabled
                          value={
                            oldOrderData?.is_admin_creation &&
                            getFileName(oldOrderData?.order_detail?.identity?.sim) !== ''
                              ? getFileName(oldOrderData?.order_detail?.identity?.sim)
                              : customer?.PersonalInfos?.sim
                              ? getFileName(customer?.PersonalInfos?.sim)
                              : 'Belum Upload SIM'
                          }
                          className="preview-image-input"
                        />
                        {(oldOrderData?.is_admin_creation &&
                          getFileName(oldOrderData?.order_detail?.identity?.sim) !== '') ||
                        customer?.PersonalInfos?.sim ? (
                          <Button
                            bgColor="#D9D9D9"
                            textColor="#000000"
                            className="preview-image-btn"
                            onClick={() =>
                              imagePreviewHandler(
                                oldOrderData?.is_admin_creation
                                  ? oldOrderData?.order_detail?.identity?.sim
                                  : IMAGE_BASE_URL + customer?.PersonalInfos?.sim,
                              )
                            }
                          >
                            Lihat
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="detail-order-airport__rent-detail">
                    <div className="detail-order-airport__rent-detail-title">
                      <h1>Detail Order</h1>
                    </div>
                    <div className="detail-order-airport__rent-detail-body">
                      <InputField
                        label="Lokasi Penjemputan"
                        disabled
                        value={oldOrderData?.order_detail?.rental_delivery_location || '-'}
                      />
                      <InputField
                        label="Lokasi Pengantaran"
                        disabled
                        value={oldOrderData?.order_detail?.rental_return_location || '-'}
                      />
                      <InputField
                        label="Detail Lokasi Penjemputan"
                        disabled
                        value={oldOrderData?.order_detail?.rental_delivery_location_detail || '-'}
                      />
                      <InputField
                        label="Detail Lokasi Pengantaran"
                        disabled
                        value={oldOrderData?.order_detail?.rental_return_location_detail || '-'}
                      />
                      <div className="detail-order__rent-detail-body__rent-date">
                        <InputField
                          label="Tanggal"
                          disabled
                          value={
                            oldOrderData?.order_detail?.start_booking_date
                              ? indonesianDateFormat(oldOrderData?.order_detail?.start_booking_date)
                              : '-'
                          }
                        />
                        <InputField
                          label="Jam"
                          disabled
                          value={(oldOrderData?.order_detail?.start_booking_time || '-') + ' ' + timezone}
                        />
                      </div>
                      <InputField
                        label="Nomor Penerbangan"
                        disabled
                        value={oldOrderData?.order_detail?.flight_number || '-'}
                      />
                      <TextAreaInput
                        style={{
                          height:
                            oldOrderData?.airport_package_vehicle_type?.split(', ').length >= 10
                              ? 96
                              : oldOrderData?.airport_package_vehicle_type?.split(', ').length >= 5
                              ? 44 * 2
                              : 44,
                        }}
                        label="Jenis Mobil"
                        disabled
                        value={oldOrderData?.airport_package_vehicle_type ?? '-'}
                        className="text-area-car-package"
                      />

                      <div className="detail-order__rent-detail-body__rent-date">
                        <InputField
                          label="Kapasitas Koper"
                          disabled
                          value={
                            oldOrderData?.order_status === 'PENDING' || oldOrderData?.order_status === 'CHECKOUT'
                              ? airportPackageVehicle?.max_suitecase
                              : oldOrderData?.order_status === 'PAID' && !selectedCar
                              ? airportPackageVehicle?.max_suitecase
                              : vehicleById?.max_suitcase ?? '-'
                          }
                        />
                        <InputField
                          label="Koper yang dibawa"
                          disabled
                          value={oldOrderData?.order_detail?.baggage ?? 0}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="detail-order-airport__price-detail">
                    <div className="detail-order-airport__price-detail-title">
                      <h1>Rincian Biaya</h1>
                    </div>

                    <div className="detail-order-airport__price-detail-body">
                      <InputField
                        label="Biaya Sewa Mobil"
                        disabled
                        value={setCurrency(oldOrderData?.booking_price) || '-'}
                      />
                      <InputField
                        label="Biaya Pengantaran"
                        disabled
                        value={setCurrency(oldOrderData?.rental_delivery_fee) || '-'}
                      />
                      <InputField
                        label="Outside Operational Hour Charge"
                        disabled
                        value={setCurrency(oldOrderData?.outside_operational_charge) || '-'}
                      />
                      <InputField
                        label="Pembayaran Dengan Point"
                        disabled
                        value={oldOrderData?.point}
                        icon={<PointsIcon />}
                        iconPosition="start"
                      />
                      <InputField
                        label="Charge Payment Gateway Platform"
                        disabled
                        value={setCurrency(oldOrderData?.disbursement?.total_amount_external_payment_fee) || '-'}
                      />
                      <InputField
                        label="Customer Service Platform"
                        disabled
                        value={setCurrency(oldOrderData?.disbursement?.total_amount_customer_service_fee) || '-'}
                      />
                      <InputField
                        label="Total Keseluruhan"
                        disabled
                        value={setCurrency(oldOrderData?.total_payment) || '-'}
                      />
                    </div>
                  </div>

                  <div className="detail-order__voucher-detail">
                    <div className="detail-order__voucher-detail-title">
                      <h1>Voucher</h1>
                    </div>
                    <div className="detail-order__voucher-detail-body">
                      <InputField label="Nama Voucher" disabled value={oldOrderData?.vouchers?.[0].name || '-'} />
                      <InputField label="Kode Voucher" disabled value={oldOrderData?.vouchers?.[0].code || '-'} />
                      <InputField
                        label="Harga Potongan"
                        disabled
                        value={setCurrency(oldOrderData?.vouchers?.[0].value) || '-'}
                      />
                    </div>
                  </div>

                  <div className="detail-order-airport__payment-detail">
                    <div className="detail-order-airport__payment-detail-title">
                      <h1>Pembayaran</h1>
                    </div>
                    <div className="detail-order-airport__payment-detail-body">
                      <InputField label="Status Pembayaran" disabled value={data?.order_status || '-'} />

                      <div
                        className={clsx(
                          'detail-order-airport__payment-detail-body__payment-method',
                          data.order_status === 'CHECKOUT' ? 'confirmation' : 'not-confirmation',
                        )}
                      >
                        <InputField
                          label="Metode Pembayaran"
                          disabled
                          // value={data?.disbursement?.payment?.method ? data?.disbursement.payment.method : '-'}
                          value={getPaymentMethodString(data.disbursement)}
                        />
                      </div>

                      {data.order_status !== 'CHECKOUT' &&
                      data.order_status !== 'RECONFIRMATION' &&
                      Object.prototype.hasOwnProperty.call(data, 'disbursement') ? (
                        <>
                          <InputField
                            label="Nama Rekening"
                            disabled
                            value={
                              Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                                ? [...data.disbursement_reconfirmation].pop()?.sender_name
                                : data?.disbursement?.sender_name || '-'
                            }
                          />

                          <InputField
                            label="Nama Bank"
                            disabled
                            value={
                              Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                                ? [...data.disbursement_reconfirmation].pop()?.sender_bank_name
                                : data?.disbursement?.sender_bank_name || '-'
                            }
                          />

                          <div className="detail-order__payment-detail-body__preview-image">
                            <InputField
                              label="Foto Bukti Transfer"
                              disabled
                              value={getFileName(
                                Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                                  ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                                  : data?.disbursement?.disbursement_confirmation_image,
                              )}
                              className="preview-image-input"
                            />

                            <Button
                              bgColor="#D9D9D9"
                              textColor="#000000"
                              className="preview-image-btn"
                              onClick={() =>
                                imagePreviewHandler(
                                  Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                                    ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                                    : data?.disbursement?.disbursement_confirmation_image,
                                )
                              }
                            >
                              Lihat
                            </Button>
                          </div>
                          <div />
                        </>
                      ) : null}
                    </div>
                  </div>

                  <div className="detail-order-airport__transaction-buttons">
                    <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
                      Kembali
                    </Button>
                  </div>
                </TabPane>
              </Tabs>
            ) : (
              <>
                <div className="detail-order-airport__renter-detail">
                  <div className="detail-order-airport__renter-detail-title">
                    <h1>Detail Penyewa</h1>
                  </div>
                  <div className="detail-order-airport__renter-detail-body">
                    <InputField label="Nama Lengkap" disabled value={data?.user_name || '-'} />
                    <InputField label="Email" disabled value={data?.email || '-'} />

                    <div className="detail-order-airport__renter-detail-body__phone-number">
                      <InputField
                        label="No Handphone"
                        disabled
                        value={data?.is_admin_creation ? data?.phone_country_code : customer?.phone_code || '-'}
                        icon={
                          <img
                            src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                            width={26}
                            height={16}
                            alt={selectedCountry?.name + ' nation flag'}
                          />
                        }
                      />
                      <InputField value={data?.phone_number || '-'} disabled />
                    </div>

                    <InputField label="Negara" disabled value={selectedCountry?.name || '-'} />

                    <div className="detail-order-airport__renter-detail-body__phone-number">
                      <div className="detail-order-airport__renter-detail-body__phone-number-wrapper">
                        <InputField
                          label="Whatsapp"
                          disabled
                          value={data?.is_admin_creation ? data?.phone_country_code : customer?.phone_code || '-'}
                          icon={
                            <img
                              src={`https://flagcdn.com/w2560/${selectedCountry?.code?.toLowerCase()}.png`}
                              width={26}
                              height={16}
                              alt={selectedCountry?.name + ' nation flag'}
                            />
                          }
                        />
                        <div className="detail-order-airport__renter-detail-body__image-wrapper"></div>
                      </div>
                      <InputField disabled value={data?.wa_number || '-'} />
                    </div>
                  </div>

                  <div className="detail-order-airport__identity-detail-body">
                    <div className="detail-order-airport__identity-detail-body__preview-image">
                      <InputField
                        label="KTP"
                        disabled
                        value={
                          data?.is_admin_creation && getFileName(data?.order_detail?.identity?.ktp) !== ''
                            ? getFileName(data?.order_detail?.identity?.ktp)
                            : customer?.PersonalInfos?.ktp
                            ? getFileName(customer?.PersonalInfos?.ktp)
                            : 'Belum Upload KTP'
                        }
                        className="preview-image-input"
                      />
                      {(data?.is_admin_creation && getFileName(data?.order_detail?.identity?.ktp) !== '') ||
                      customer?.PersonalInfos?.ktp ? (
                        <Button
                          bgColor="#D9D9D9"
                          textColor="#000000"
                          className="preview-image-btn"
                          onClick={() =>
                            imagePreviewHandler(
                              data?.is_admin_creation
                                ? data?.order_detail?.identity?.ktp
                                : IMAGE_BASE_URL + customer?.PersonalInfos?.ktp,
                            )
                          }
                        >
                          Lihat
                        </Button>
                      ) : null}
                    </div>

                    <div className="detail-order-airport__identity-detail-body__preview-image">
                      <InputField
                        label="SIM"
                        disabled
                        value={
                          data?.is_admin_creation && getFileName(data?.order_detail?.identity?.sim) !== ''
                            ? getFileName(data?.order_detail?.identity?.sim)
                            : customer?.PersonalInfos?.sim
                            ? getFileName(customer?.PersonalInfos?.sim)
                            : 'Belum Upload SIM'
                        }
                        className="preview-image-input"
                      />
                      {(data?.is_admin_creation && getFileName(data?.order_detail?.identity?.sim) !== '') ||
                      customer?.PersonalInfos?.sim ? (
                        <Button
                          bgColor="#D9D9D9"
                          textColor="#000000"
                          className="preview-image-btn"
                          onClick={() =>
                            imagePreviewHandler(
                              data?.is_admin_creation
                                ? data?.order_detail?.identity?.sim
                                : IMAGE_BASE_URL + customer?.PersonalInfos?.sim,
                            )
                          }
                        >
                          Lihat
                        </Button>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="detail-order-airport__rent-detail">
                  <div className="detail-order-airport__rent-detail-title">
                    <h1>Detail Order</h1>
                  </div>
                  <div className="detail-order-airport__rent-detail-body">
                    <InputField
                      label="Lokasi Penjemputan"
                      disabled
                      value={data?.order_detail?.rental_delivery_location || '-'}
                    />
                    <InputField
                      label="Lokasi Pengantaran"
                      disabled
                      value={data?.order_detail?.rental_return_location || '-'}
                    />
                    <InputField
                      label="Detail Lokasi Penjemputan"
                      disabled
                      value={data?.order_detail?.rental_delivery_location_detail || '-'}
                    />
                    <InputField
                      label="Detail Lokasi Pengantaran"
                      disabled
                      value={data?.order_detail?.rental_return_location_detail || '-'}
                    />
                    <div className="detail-order__rent-detail-body__rent-date">
                      <InputField
                        label="Tanggal"
                        disabled
                        value={
                          data?.order_detail?.start_booking_date
                            ? indonesianDateFormat(data?.order_detail?.start_booking_date)
                            : '-'
                        }
                      />
                      <InputField
                        label="Jam"
                        disabled
                        value={(data?.order_detail?.start_booking_time || '-') + ' ' + timezone}
                      />
                    </div>
                    <InputField label="Nomor Penerbangan" disabled value={data?.order_detail?.flight_number || '-'} />
                    <TextAreaInput
                      style={{
                        height:
                          data?.airport_package_vehicle_type?.split(', ').length >= 10
                            ? 96
                            : data?.airport_package_vehicle_type?.split(', ').length >= 5
                            ? 44 * 2
                            : 44,
                      }}
                      label="Jenis Mobil"
                      disabled
                      value={data?.airport_package_vehicle_type ?? '-'}
                      className="text-area-car-package"
                    />

                    <div className="detail-order__rent-detail-body__rent-date">
                      <InputField
                        label="Kapasitas Koper"
                        disabled
                        value={
                          data?.order_status === 'PENDING' || data?.order_status === 'CHECKOUT'
                            ? airportPackageVehicle?.max_suitecase
                            : data?.order_status === 'PAID' && !selectedCar
                            ? airportPackageVehicle?.max_suitecase
                            : vehicleById?.max_suitcase ?? '-'
                        }
                      />
                      <InputField label="Koper yang dibawa" disabled value={data?.order_detail?.baggage ?? 0} />
                    </div>
                  </div>
                </div>

                <div className="detail-order-airport__price-detail">
                  <div className="detail-order-airport__price-detail-title">
                    <h1>Rincian Biaya</h1>
                  </div>

                  <div className="detail-order-airport__price-detail-body">
                    <InputField label="Biaya Sewa Mobil" disabled value={setCurrency(data?.booking_price) || '-'} />
                    <InputField
                      label="Biaya Pengantaran"
                      disabled
                      value={setCurrency(data?.rental_delivery_fee) || '-'}
                    />
                    <InputField
                      label="Outside Operational Hour Charge"
                      disabled
                      value={setCurrency(data?.outside_operational_charge) || '-'}
                    />
                    <InputField
                      label="Pembayaran Dengan Point"
                      disabled
                      value={data?.point}
                      icon={<PointsIcon />}
                      iconPosition="start"
                    />
                    <InputField
                      label="Charge Payment Gateway Platform"
                      disabled
                      value={setCurrency(data?.disbursement?.total_amount_external_payment_fee) || '-'}
                    />
                    <InputField
                      label="Customer Service Platform"
                      disabled
                      value={setCurrency(data?.disbursement?.total_amount_customer_service_fee) || '-'}
                    />
                    <InputField
                      label="Total Harga Bersih"
                      disabled
                      value={setCurrency(data?.disbursement?.total_net_amount) || '-'}
                    />
                    <InputField
                      label="Total Bruto"
                      disabled
                      value={setCurrency(data?.disbursement?.total_amount_with_customer_service_fee) || '-'}
                    />
                    <InputField label="Total Keseluruhan" disabled value={setCurrency(data?.total_payment) || '-'} />
                    {Object.prototype.hasOwnProperty.call(data, 'order_approval_history') ? (
                      <InputField
                        label="Harga Selisih"
                        disabled
                        value={setCurrency(data?.total_diff_price_with_approval) || '-'}
                      />
                    ) : null}
                  </div>
                </div>

                <div className="detail-order__voucher-detail">
                  <div className="detail-order__voucher-detail-title">
                    <h1>Voucher</h1>
                  </div>
                  <div className="detail-order__voucher-detail-body">
                    <InputField label="Nama Voucher" disabled value={data?.vouchers?.[0].name || '-'} />
                    <InputField label="Kode Voucher" disabled value={data?.vouchers?.[0].code || '-'} />
                    <InputField label="Harga Potongan" disabled value={setCurrency(data?.vouchers?.[0].value) || '-'} />
                  </div>
                </div>

                <div className="detail-order-airport__payment-detail">
                  <div className="detail-order-airport__payment-detail-title">
                    <h1>Pembayaran</h1>
                  </div>
                  <div className="detail-order-airport__payment-detail-body">
                    <InputField label="Status Pembayaran" disabled value={data?.order_status || '-'} />

                    <div
                      className={clsx(
                        'detail-order-airport__payment-detail-body__payment-method',
                        data.order_status === 'CHECKOUT' ? 'confirmation' : 'not-confirmation',
                      )}
                    >
                      <InputField
                        label="Metode Pembayaran"
                        disabled
                        // value={data?.disbursement?.payment?.method ? data?.disbursement.payment.method : '-'}
                        value={getPaymentMethodString(data.disbursement)}
                      />
                      {data.order_status === 'CHECKOUT' &&
                      data?.order_approval_status !== 'WAITING_APPROVAL_DELETE_ORDER' ? (
                        <Button
                          height={44}
                          onClick={() => setShowBankTransferConfirmation(true)}
                          disabled={isPaymentValid}
                        >
                          Konfirmasi
                        </Button>
                      ) : null}
                    </div>

                    {data.order_status !== 'CHECKOUT' &&
                    data.order_status !== 'RECONFIRMATION' &&
                    Object.prototype.hasOwnProperty.call(data, 'disbursement') ? (
                      <>
                        <InputField
                          label="Nama Rekening"
                          disabled
                          value={
                            Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                              ? [...data.disbursement_reconfirmation].pop()?.sender_name
                              : data?.disbursement?.sender_name || '-'
                          }
                        />

                        <InputField
                          label="Nama Bank"
                          disabled
                          value={
                            Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                              ? [...data.disbursement_reconfirmation].pop()?.sender_bank_name
                              : data?.disbursement?.sender_bank_name || '-'
                          }
                        />

                        <div className="detail-order__payment-detail-body__preview-image">
                          <InputField
                            label="Foto Bukti Transfer"
                            disabled
                            value={getFileName(
                              Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                                ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                                : data?.disbursement?.disbursement_confirmation_image,
                            )}
                            className="preview-image-input"
                          />

                          <Button
                            bgColor="#D9D9D9"
                            textColor="#000000"
                            className="preview-image-btn"
                            onClick={() =>
                              imagePreviewHandler(
                                Object.prototype.hasOwnProperty.call(data, 'disbursement_reconfirmation')
                                  ? [...data.disbursement_reconfirmation].pop()?.reconfirmation_image
                                  : data?.disbursement?.disbursement_confirmation_image,
                              )
                            }
                          >
                            Lihat
                          </Button>
                        </div>
                        <div />
                      </>
                    ) : null}
                  </div>
                </div>

                <div className="detail-order-airport__transaction-buttons">
                  <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
                    Kembali
                  </Button>
                  {data.order_status === 'CHECKOUT' && !data.order_approval_status ? (
                    <Button
                      size="sm"
                      className="button"
                      width={208}
                      variant="danger"
                      onClick={() => setShowRejectOrderModal(true)}
                    >
                      Tolak Pesanan
                    </Button>
                  ) : null}
                  {data.order_status === 'PAID' && !data.order_approval_status ? (
                    <Button variant="success" className="button" width={208} size="sm" onClick={handleProcess}>
                      Proses
                    </Button>
                  ) : null}
                </div>
              </>
            )}
          </TableWrapper>
        </div>
      )}
      {showPriceDifference &&
        ReactDOM.createPortal(
          <PriceDifferenceModal
            setShowPriceDifference={setShowPriceDifference}
            Icon={AirportIcon}
            title="Airport Transfer"
            data={data}
          />,
          document.getElementById('modal'),
        )}
    </>
  );
};

export default DetailOrder;
