import { useAppContext } from 'components/Context/AppContext';
import React, { useEffect, useState } from 'react';
import Modals from '../Modals';
import { Button, InputField } from 'components/Global';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as GarageFilledIcon } from 'icons/garage-filled-icon.svg';
import { addGarages, updateGarages } from 'features/garages/actions';
import { ReactComponent as MagnifierIcon } from 'icons/magnifier.svg';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import SelectFieldTime from 'components/Global/SelectFieldTime';
import { resetGarageDetail } from 'features/garages/slice';
import { checkPermission } from 'utils/functionality';

const NewGarageModal = () => {
  const dispatch = useDispatch();

  const { setShowAddNewGarage, showToast } = useAppContext();
  const { data } = useSelector((state) => state.rentalLocation);
  const { garageDetail } = useSelector((state) => state.garages);
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);

  const [garageName, setGarageName] = useState('');
  const [garageAddress, setGarageAddress] = useState('');
  const [garageMapsLink, setGarageMapsLink] = useState('');
  const [jamBuka, setJamBuka] = useState('');
  const [jamTutup, setJamTutup] = useState('');
  const [cityId, setCityId] = useState(0);

  // fill every field with garage detail data if detail garage
  useEffect(() => {
    if (Object.keys(garageDetail).length === 0) return;

    setGarageName(garageDetail.name);
    setJamBuka(garageDetail.start_time);
    setJamTutup(garageDetail.end_time);
    setCityId(garageDetail.location_id);
    setGarageMapsLink(garageDetail.map_link);
    setGarageAddress(garageDetail.address_details);
  }, [garageDetail]);

  // function sendPayload action
  const sendPayloadData = async (action, payload, fulfilledMessage, errorMessage1, errorMessage2) => {
    try {
      const res = await dispatch(
        action === 'ADD'
          ? addGarages(payload)
          : action === 'UPDATE'
          ? updateGarages({ id: garageDetail.id, payload })
          : () => {},
      );
      if (res.meta?.requestStatus === 'fulfilled') {
        showToast({ type: 'success', message: fulfilledMessage });
        setShowAddNewGarage(false);
        return;
      }
      showToast({ type: 'error', message: errorMessage1 });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: `${errorMessage2}: ${error}` });
    }
  };

  const handleAddGarage = async () => {
    const payload = {
      name: garageName,
      location_id: parseInt(cityId),
      start_time: jamBuka,
      end_time: jamTutup,
      address_details: garageAddress,
      map_link: garageMapsLink,
    };

    // validation
    const validName = garageName.replace(/\s/).length !== 0;
    const validLocation = cityId !== 0;
    const validStartTime = jamBuka.replace(/\s/).length !== 0;
    const validationEndTime = jamTutup.replace(/\s/).length !== 0;
    const validAddressDetails = garageAddress.replace(/\s/).length !== 0;
    const validMapLink = garageMapsLink.replace(/\s/).length !== 0;

    // check validation
    if (!validName) {
      return showToast({ type: 'error', message: 'Masukan Nama Garasi' });
    } else if (!validStartTime) {
      return showToast({ type: 'error', message: 'Pilih Jam Buka' });
    } else if (!validationEndTime) {
      return showToast({ type: 'error', message: 'Pilih Jam Tutup' });
    } else if (!validLocation) {
      return showToast({ type: 'error', message: 'Pilih Lokasi Rental' });
    } else if (!validMapLink) {
      return showToast({ type: 'error', message: 'Masukan Link Gmaps' });
    } else if (!validAddressDetails) {
      return showToast({ type: 'error', message: 'Masukan Alamat Garasi' });
    }

    // if add garage
    if (Object.keys(garageDetail).length === 0) {
      sendPayloadData('ADD', payload, 'Berhasil Tambah Garasi', 'Gagal Tambah Garasi', 'Terjadi Kesalahan');
      return;
    }

    // if update garage
    sendPayloadData('UPDATE', payload, 'Berhasil Update Garasi', 'Gagal Update Garasi', 'Terjadi Kesalahan');
    dispatch(resetGarageDetail());
  };

  return (
    <Modals
      setState={setShowAddNewGarage}
      title={Object.keys(garageDetail).length === 0 ? 'Tambah Garasi' : 'Detail Garasi'}
      icon={<GarageFilledIcon />}
    >
      <div className="garage-detail">
        <InputField
          className={'garage-input'}
          label={'Nama Garasi'}
          placeholder={'Masukkan Nama Garasi'}
          value={garageName}
          onChange={(e) => setGarageName(e.target.value)}
          id="nama-garasi"
          name={'username'}
        />

        <div className="garage-detail__time">
          <SelectFieldTime
            label="Jam Buka"
            htmlFor="jam-buka"
            placeholder="00:00"
            value={jamBuka}
            onChange={(hour, minute) => {
              setJamBuka(`${hour}:${minute ? minute : '00'}`);
            }}
            icon={false}
          />
          <SelectFieldTime
            label="Jam Tutup"
            htmlFor="jam-tutup"
            placeholder="00:00"
            value={jamTutup}
            onChange={(hour, minute) => {
              setJamTutup(`${hour}:${minute ? minute : '00'}`);
            }}
            disable={jamBuka === '' ? true : false}
            icon={false}
          />
        </div>

        <SelectFieldDropdown
          label="Lokasi Rental"
          type="select"
          htmlFor="garage-city"
          data={data}
          value={cityId}
          placeholder={'Pilih Lokasi'}
          onChange={(e) => setCityId(e.target.id)}
        />

        <InputField
          className={'garage-input'}
          label={'Link Gmaps'}
          placeholder={'Masukkan Link'}
          value={garageMapsLink}
          onChange={(e) => setGarageMapsLink(e.target.value)}
          id="garasi-map-link"
          name={'map_link'}
        />

        <InputField
          className={'garage-input'}
          label={'Alamat Garasi'}
          placeholder={'Masukkan Alamat Garasi'}
          value={garageAddress}
          onChange={(e) => setGarageAddress(e.target.value)}
          icon={<MagnifierIcon />}
        />
      </div>

      {(checkPermission(offCanvasMenu, currentMenu, 'update') || Object.keys(garageDetail).length === 0) && (
        <Button
          type="button"
          onClick={handleAddGarage}
          width={267}
          style={{ marginLeft: 'auto' }}
          className="garage-btn"
        >
          {Object.keys(garageDetail).length === 0 ? 'Tambah' : 'Simpan'}
        </Button>
      )}
    </Modals>
  );
};

export default NewGarageModal;
