import React from 'react';
import { Button } from 'components/Global';
import { useAppContext } from 'components/Context/AppContext';

const ActionButtons = ({ data, backHandler, RenderedProcessButton }) => {
  const { setShowRejectOrderModal } = useAppContext();

  return (
    <div className="detail-order__transaction-buttons">
      <Button variant="outline" className="button" width={208} size="sm" onClick={backHandler}>
        Kembali
      </Button>

      {data.order_status === 'CHECKOUT' && !data.order_approval_status ? (
        <Button size="sm" className="button" width={208} variant="danger" onClick={() => setShowRejectOrderModal(true)}>
          Tolak Orderan
        </Button>
      ) : null}

      <RenderedProcessButton />
    </div>
  );
};

export default ActionButtons;
