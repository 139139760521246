import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getCategoryById = createAsyncThunk('category/getCategoryById', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/category/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getAllCategories = createAsyncThunk('category/getAllCategories', async (params, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const { page = 1, search, sortBy, sortOrder, useGroupAirportPackage } = params;

  let newUrl = `${BASE_URL}/${API_VERSION}/category?limit=10&page=${page}`;

  if (search) {
    newUrl += `&search=${search}`;
  }

  if (sortBy) {
    newUrl += `&sort_by=${sortBy}`;
  }

  if (sortOrder) {
    newUrl += `&sort_order=${sortOrder}`;
  }

  if (useGroupAirportPackage) {
    newUrl += `&use_group_airport_package=${useGroupAirportPackage}`;
  }

  try {
    const response = await axios
      .get(newUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => res);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createCategory = createAsyncThunk('category/createCategory', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(`${BASE_URL}/${API_VERSION}/category`, payload, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    thunkAPI.dispatch(getAllCategories({ page: 1 }));

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const editCategory = createAsyncThunk('category/editCategorye', async ({ payload, id }, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.put(
      `${BASE_URL}/${API_VERSION}/category/${id}`,
      { ...payload },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    thunkAPI.dispatch(getAllCategories({ page: 1 }));

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deleteCategory = createAsyncThunk('category/deleteCategory', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.delete(`${BASE_URL}/${API_VERSION}/category/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    thunkAPI.dispatch(getAllCategories({ page: 1 }));
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getVehicleByCategory = createAsyncThunk('category/getVehicleByCategory', async (page = 1, thunkAPI) => {
  try {
    const response = await axios
      .get(`${BASE_URL}/${API_VERSION}/category?&page=${page}&show_vehicle=true`)
      .then((res) => res);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getSearchVehicleByCategory = createAsyncThunk(
  'category/getSearchVehicleByCategory',
  async ({ page = 1, search }, thunkAPI) => {
    let mainUrl = `${BASE_URL}/${API_VERSION}/category?&page=${page}&show_vehicle=true`;

    if (search) {
      mainUrl += `&search=${search}`;
    }

    try {
      const response = await axios.get(mainUrl).then((res) => res);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
