import React, { useEffect } from 'react';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { ReactComponent as BellIcon } from 'icons/bell-filled-icon.svg';
// import { ReactComponent as SuccessIcon } from 'icons/toast-success-icon.svg';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Button, TableWrapper } from 'components/Global';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { id } from 'date-fns/locale';
import { fetchOrderById } from 'features/orders/actions';

const NotificationDetail = ({ setShowDetail, getNotificationIcon, getAllInboxes }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inbox = useSelector((state) => state.notificationInbox.selected);
  const { data: orderData } = useSelector((state) => state.detailOrder);

  const handleClick = () => {
    const orderType =
      orderData.order_type_id === 1
        ? orderData.order_detail.without_driver
          ? 'without-driver'
          : 'with-driver'
        : 'airport-transfer';
    navigate({
      pathname: `/${orderType}/detail/${orderData.transaction_key}`,
      search: createSearchParams({
        customerId: inbox.custom_data?.customer_id,
      }).toString(),
    });
  };

  const handleBack = () => {
    setShowDetail(false);
    dispatch(getAllInboxes);
  };

  useEffect(() => {
    if (!Object.prototype.hasOwnProperty.call(inbox, 'custom_data')) return;
    dispatch(fetchOrderById(inbox.custom_data.order_id));
  }, [inbox]);

  return (
    <div className="notification-detail">
      <div className="notification-detail__back-btn" onClick={handleBack}>
        <LeftArrow />
        <p>Kembali</p>
      </div>

      <div className="notification-detail__body">
        <TableWrapper title="Notifikasi" icon={<BellIcon fill="#009EF7" width="25px" height="25px" />}>
          <div className="notification-detail__item">
            <div className="notification-detail__item--header">
              {getNotificationIcon(inbox.title?.toLowerCase())}
              <div className="notification-detail__item--body">
                <h1 className="notification-detail__item--body-title">{inbox.title}</h1>
                <p className="notification-detail__item--body-created-at">
                  {inbox.created_at &&
                    format(parseISO(inbox.created_at), 'dd LLLL yyyy, HH:mm', {
                      locale: id,
                    })}
                </p>
                <p className="notification-detail__item--body-message">{inbox.message}</p>
              </div>
            </div>
            <div className="notification-detail__item--button">
              <Button onClick={handleClick} width="135px" height="39px" className="button-order">
                Lihat Order
              </Button>
            </div>
          </div>
        </TableWrapper>
      </div>
    </div>
  );
};

export default NotificationDetail;
