import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getApprovalSettings = createAsyncThunk('approvals/getApprovalSettings', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;
  const { key, limit, page = 1 } = payload;

  let mainUrl = `${BASE_URL}/${API_VERSION}/admin/approval-settings?limit=${limit}&page=${page}`;

  if (key) {
    mainUrl += `&key=${key}`;
  }

  try {
    const response = await axios
      .get(mainUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => res);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data.message);
  }
});

export const getApprovalSettingDetail = createAsyncThunk('approvals/getApprovalSettingDetail', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/admin/approval-settings/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data.message);
  }
});

export const getAllApprovalData = createAsyncThunk('approvals/getAllApprovalData', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const { limit = 10, page = 1 } = payload;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/approvals?limit=${limit}&page=${page}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data.message);
  }
});

export const getApprovalDataById = createAsyncThunk('approvals/getApprovalDataById', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/admin/approvals/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data.message);
  }
});

export const updateApprovalStatus = createAsyncThunk('approvals/updateApprovalStatus', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const { id, status } = payload;

  try {
    const response = await axios.put(
      `${BASE_URL}/${API_VERSION}/approvals/${id}`,
      {
        status,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data.message);
  }
});

export const updateApprovalOrder = createAsyncThunk('orders/updateApprovalOrder', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const res = await axios.post(
      `${BASE_URL}/${API_VERSION}/admin/approvals`,
      { ...payload },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );

    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
